import React from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import CongratulationsImage from '../../../images/conglatulations.svg';
import { Button } from '../AllButtons';
import { Goal } from '../Goal';
import { PageTitle } from '../PageTitle';

const Congratulations = ({ intl }) => {
  const history = useHistory();
  return (
    <>
      <PageTitle title={'Confirmation'} insideContainer />
      <div className="text-center bg-light-blue border-radius-16 mt-5 py-10 mt-25">
        <div>
          <img src={CongratulationsImage} alt="Clapping hands" />
        </div>
        <div className="font-size-33 font-weight-bolder">
          {intl.formatMessage({ id: 'CONGRATULATIONS_PAGE.HEADER' })}
        </div>
        <div className="font-size-14">
          {intl.formatMessage({ id: 'CONGRATULATIONS_PAGE.SUB_HEADER' })}
        </div>
      </div>

      <div className="font-size-25 text-center mt-7">
        {intl.formatMessage({ id: 'CONGRATULATIONS_PAGE.REMINDER_TITLE' })}
      </div>

      <Goal
        goalName={intl.formatMessage({
          id: 'CONGRATULATIONS_PAGE.REMINDER_WEEKLY',
        })}
        goalDescription={intl.formatMessage({
          id: 'CONGRATULATIONS_PAGE.REMINDER_WEEKLY_DESCRIPTION',
        })}
        link={'/portfolio/reminder/day'}
      />

      <Goal
        goalName={intl.formatMessage({
          id: 'CONGRATULATIONS_PAGE.REMINDER_MONTHLY',
        })}
        goalDescription={intl.formatMessage({
          id: 'CONGRATULATIONS_PAGE.REMINDER_MONTHLY_DESCRIPTION',
        })}
        link={'/portfolio/reminder/calendar'}
      />

      <Button
        buttonText={intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
        classes={'w-100 mt-5 bg-red-100 red-text font-size-20 px-15 py-5'}
        buttonType="submit"
        onClick={() => history.push('/portfolio')}
      />
    </>
  );
};

export default injectIntl(Congratulations);
