import React from 'react';
// import { moneyFormat } from '../../../../../helpers/moneyFormat';
import { moneyFormat } from '../../../../helpers/moneyFormat';
// import InputValidationErrorMessage from '../../../../Common/InputValidationErrorMessage';
import InputValidationErrorMessage from '../../../Common/InputValidationErrorMessage';

const DisplayCorrectErrorMessage = ({
  formikTouched,
  formikError,
  amount,
  currency,
  input,
}) => {
  //TODO: this implementation is super unscalable and should be redone !!
  //remove reliance of local Storage and input names !!
  const renderErrors = () => {
    if (formikTouched && formikError) {
      return (
        <div className="goal-amount-slider-container fv-plugins-message-container">
          <div className="fv-help-block">{formikError}</div>
        </div>
      );
    } else {
      if (input !== 'goalAmount') {
        const planMinimumInvestableAmount = localStorage.getItem(
          'planMinimumInvestableAmounts'
        );
        const planMinimumInvestableAmountObj = JSON.parse(
          planMinimumInvestableAmount
        );
        const paymentCurrency = currency?.currency;
        const minAmount = planMinimumInvestableAmountObj?.[paymentCurrency]
          ? planMinimumInvestableAmountObj?.[paymentCurrency]
          : currency?.min;

        if (amount < Number(minAmount)) {
          return (
            <InputValidationErrorMessage
              message={`The minimum amount is ${moneyFormat(
                Number(minAmount)
              )} ${currency?.currency}`}
            />
          );
        }
      }

      if (Number(amount) > Number(currency?.max)) {
        return (
          <InputValidationErrorMessage
            message={`The maximum amount is ${moneyFormat(currency?.max)} ${
              currency?.currency
            }`}
          />
        );
      }
    }
  };

  return <>{renderErrors()}</>;
};

export default DisplayCorrectErrorMessage;
