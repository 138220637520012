import React, { useMemo } from 'react';
import objectPath from 'object-path';

import { useHtmlClassService } from '../_core/MetronicLayout';
import { Aside } from './aside/Aside';
import { LayoutInit } from './LayoutInit';
import { BottomMenuList } from './aside/aside-menu/BottomMenu';
import { useHistory } from 'react-router-dom';

export function Layout({ children }) {
  const uiService = useHtmlClassService();
  const history = useHistory();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        'header.self.fixed.desktop'
      ),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    };
  }, [uiService]);

  const currentPath = history.location.pathname;

  const wizardPaths = [
    '/welcome',
    '/how-it-works',
    '/setup-your-goal',
    '/select-goal',
    '/wizard/investment-experience',
    '/wizard/familiar-investments',
  ];

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/*begin::Main*/}
      <div className="d-flex flex-column flex-root">
        {/*begin::Page*/}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/*begin::Wrapper*/}
          <div
            className="d-flex flex-column flex-row-fluid wrapper content-container"
            id="kt_wrapper"
          >
            {/*begin::Content*/}
            <div
              id="kt_content"
              className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
              style={{ backgroundColor: '#fff' }}
            >
              {/*begin::Entry*/}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  {/*begin::Container*/}
                  <div className={layoutProps.contentContainerClasses}>
                    {children}
                  </div>
                  {/*end::Container*/}
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/*end::Entry*/}
            </div>
            {/*end::Content*/}
            {!wizardPaths.includes(currentPath) ? <BottomMenuList /> : null}
          </div>
          {/*end::Wrapper*/}
        </div>
        {/*end::Page*/}
      </div>
      {/*end::Main*/}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}
