export const paymentMethod = {
  KENYAN_CARD: 'KENYAN_CARD',
  INTERNATIONAL_CARD: 'INTERNATIONAL_CARD',
  MPESA: 'MPESA',
  BANK_TRANSFER: 'BANK_TRANSFER',
  CARD: 'CARD',
};

export const sameCurrencyConversion = {
  KES_TO_KES: 'KES_TO_KES',
  USD_TO_USD: 'USD_TO_USD',
  RWF_TO_RWF: 'RWF_TO_RWF',

  EUR_TO_EUR: 'EUR_TO_EUR',
  GBP_TO_GBP: 'GBP_TO_GBP',
  TZS_TO_TZS: 'TZS_TO_TZS',
};

export const paymentMethodCharge = {
  KENYAN_CREDIT_CARD_PERC: 2.9,
  INTERNATIONAL_CREDIT_CARD_PERC: 3.8,
};

export const errorCodes = {
  BAD_REQUEST: 400,
  UNPROCESSABLE_ENTITY: 422,
};
export const planType = {
  CASH: 'cash',
  BOND: 'bond',
  EQUITY: 'equity',
};

export const errorMessages = {
  VERIFY_EMAIL: 'Please verify your email address',
};

export const environment = {
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
};

export const mathSign = {
  NEGATIVE: '-',
  POSITIVE: '+',
};

export const status = {
  PENDING: 'PENDING',
  TRANSFER_INCOMPLETE: 'TRANSFER_INCOMPLETE',
  COMPLETED: 'COMPLETED',
};

export const operationType = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

export const passwordRegex = /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]?)[\w!@#$%^&*()+=><,."'`~\/\\\-\?|}{\[\]:;±§]{8,16}$/;

export const passwordValidationMessage =
  'Must be in a range of 8-16 characters. At least one upper case letter, one lower case, and one number.';

export const letterNumberRegex = /^[a-zA-Z0-9',.\- ]+$/;
export const specialCharError =
  "The only special characters allowed are: ('), (,), (.) and (-)";

export const subscriptionType = {
  PREMIUM: 'PREMIUM',
  BASIC: 'BASIC',
};

export const PaymentType = {
  SUBSCRIPTION_PAYMENT: 'SUBSCRIPTION_PAYMENT',
  INVESTMENT_PAYMENT: 'INVESTMENT_PAYMENT',
};

export const OperationType = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW',
};

export const kycStatuses = {
  INCOMPLETE: 'INCOMPLETE',
};

export const transactionType = {
  GOAL: 'GOAL',
  DIRECT: 'DIRECT',
  ARCHIVED: 'ARCHIVED',
};

export const Currency = {
  KES: 'KES',
  DOLLAR: 'USD',
};

export const FundType = {
  SAVE: 'SAVE',
  INVEST: 'INVEST',
};

export const pieChartColorSet = {
  bond: '#0E2A5F',
  cash: '#FF7F30',
  equity: '#0071CE',
};

export const fixAndVariableColors = {
  fix: '#EC1A23',
  variable: '#85C3E8',
};

export const daysOfTheWeek = [
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
  { name: 'Sunday', value: 7 },
];

export const period = {
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
};

export const vowels = ['a', 'e', 'u', 'o', 'i'];
