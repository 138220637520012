import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import CloseModal from './CloseModal';
import { Button } from './AllButtons';
import { Divider } from './Divider';
import { injectIntl } from 'react-intl';

function getModalStyle() {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '60%',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '90%',
      marginLeft: '-5%',
    },
  },
}));

const ConfirmModal = ({
  open,
  setOpen,
  confirm,
  text,
  title,
  intl,
  error,
  success,
  setErrorMessage,
  setSuccessMessage,
}) => {
  const [modalStyle] = useState(getModalStyle);
  const classes = useStyles();

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} bg-light-blue border-radius-16 py-10`}
    >
      <div className="float-right">
        <CloseModal
          setOpen={setOpen}
          setSuccessMessage={setSuccessMessage}
          setErrorMessage={setErrorMessage}
        />
      </div>
      <h6 className="font-size-20 font-weight-bold mt-10">{title}</h6>

      <Divider className={'dark-gray-divider mt-5'} />
      <p className="my-10 font-size-17" id="simple-modal-description">
        {error ? (
          <span className="red-text">{error}</span>
        ) : success ? (
          <span>{success}</span>
        ) : (
          text
        )}
      </p>

      <div className="mt-20">
        {error || success ? (
          <Button
            buttonText={intl.formatMessage({
              id: 'BUTTONS.CLOSE',
            })}
            classes={'w-100 bg-red white-text font-size-17 px-5 py-5'}
            onClick={() => {
              setSuccessMessage(null);
              setErrorMessage(null);
              setOpen(false);
            }}
          />
        ) : (
          <>
            <Button
              buttonText={intl.formatMessage({
                id: 'BUTTONS.CONTINUE',
              })}
              classes={'w-100 bg-red white-text font-size-17 px-5 py-5'}
              onClick={() => {
                confirm();
              }}
            />

            <Button
              buttonText={intl.formatMessage({
                id: 'BUTTONS.CANCEL2',
              })}
              classes={'w-100 bg-red-100 red-text font-size-17 px-5 py-5 mt-5'}
              onClick={() => setOpen(false)}
            />
          </>
        )}
      </div>
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default injectIntl(ConfirmModal);
