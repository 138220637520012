import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import { createGoal, getGoalNames } from '../_redux/goalActions';
import * as goalActions from '../../Goal/_redux/goalReducers';

import ErrorCard from '../../Common/ErrorCard';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import goalIcon from '../../../icons/goalIcon.svg';

import TextModal from '../components/LearnMore';
import { Divider } from '../../Common/Divider';
import { GoalSummaryItem } from '../components/GoalSummaryItem';
import { Button } from '../../Common/AllButtons';
import CustomCheckBox from '../../Common/CustomCheckBox';
import TransactionDetailsContent from '../components/TransactionDetailsModalContent';
import ConfirmPayment from '../components/ConfirmPaymentModal';
import { PageTitle } from '../../Common/PageTitle';
// import PayWithMpesa from '../../Invest/components/PayWithMpesa';
import { moneyFormat } from '../../../helpers/moneyFormat';

const GoalSummary = ({ intl, updateGoalDetails }) => {
  let history = useHistory();
  const { goalDetails } = useSelector((state) => state.goal);
  const profile = useSelector((state) => state.profile);
  const [goalNames, setGoalNames] = useState();
  const [alert, setAlert] = useState();

  const [payWithMpesa, setPayWithMpesa] = useState();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [acceptTermsAndCondition, setAcceptTermsAndCondition] = useState(false);
  const [
    openTransactionDetailsModal,
    setOpenTransactionDetailsModal,
  ] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const fetchGoalNames = async () => {
    try {
      const response = await getGoalNames();
      setGoalNames(response?.data?.items);
    } catch (error) {
      setAlert({
        alertMessage: error?.response?.data?.message,
        alertMessageType: 'error',
      });
    }
  };

  useEffect(() => {
    fetchGoalNames();
  }, []);

  const createTheGoal = async () => {
    setLoading(true);
    localStorage.removeItem('goalDataForPayment');
    const action = localStorage.getItem('action');
    if (action === 'TOP_UP') {
      setOpenTransactionDetailsModal(false);
      // setOpenConfirmationModal(true);
      history.push(`/goal/pay-with-mpesa`);
      setPayWithMpesa(true);
      return;
    }

    let goalCreationResponse;

    try {
      if (goalDetails?.goalNameIsRequired) {
        const otherGoal = goalNames?.find((goal) => goal.goalNameIsRequired);
        goalCreationResponse = await createGoal(
          goalDetails?.name,
          convertAmountToNumber(goalDetails?.goalAmount),
          'KES',
          goalDetails?.targetDate,
          convertAmountToNumber(goalDetails?.recurringAmount),
          convertAmountToNumber(goalDetails?.initialInvestment),
          Number(goalDetails?.investmentProfile)
            ? Number(goalDetails?.investmentProfile)
            : Number(profile?.riskProfile?.id),
          Number(otherGoal?.id)
        );
      } else {
        goalCreationResponse = await createGoal(
          null,
          convertAmountToNumber(goalDetails?.goalAmount),
          'KES',
          goalDetails?.targetDate,
          convertAmountToNumber(goalDetails?.recurringAmount),
          convertAmountToNumber(goalDetails?.initialInvestment),
          Number(goalDetails?.investmentProfile)
            ? Number(goalDetails?.investmentProfile)
            : Number(profile?.riskProfile?.id),
          Number(goalDetails?.id)
        );
      }

      if (goalCreationResponse.status === 201) {
        setLoading(false);
        localStorage.removeItem('goal');
        updateGoalDetails(goalCreationResponse?.data?.data);
        setOpenTransactionDetailsModal(false);
        // setOpenConfirmationModal(true);
        history.push(`/portfolio/goal/pay-with-mpesa`);
        setPayWithMpesa(true);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const displayTargetPeriod = () => {
    let targetPeriod;
    if (goalDetails?.years && goalDetails?.months) {
      targetPeriod = `${goalDetails?.years} ${
        goalDetails?.years > 1 ? 'years' : 'year'
      } and ${goalDetails?.months} ${
        goalDetails?.months > 1 ? 'months' : 'month'
      }`;
    } else if (goalDetails?.years && !goalDetails?.months) {
      targetPeriod = `${goalDetails?.years} ${
        goalDetails?.years > 1 ? 'years' : 'year'
      }`;
    } else if (!goalDetails?.years && goalDetails?.months) {
      targetPeriod = `${goalDetails?.months} ${
        goalDetails?.months > 1 ? 'months' : 'month'
      }`;
    }

    return targetPeriod;
  };

  if (loading) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'GOAL_SUMMARY_PAGE.TITLE' })}
        insideContainer
        previousPage={'/portfolio/goal/calculator'}
      />
      <div className="mt-35">
        {errorMessage ? (
          <ErrorCard errorMessage={errorMessage} />
        ) : (
          <>
            <div className="goal-summary-container">
              <div className="mb-2">
                <div></div>
                <div className="d-flex align-items-center">
                  <img src={goalIcon} alt="Goal Icon" />
                  <div className="font-size-20 red-text ml-5">
                    {goalDetails?.name}
                  </div>
                </div>
              </div>
              <Divider className="gray-divider" />
              <GoalSummaryItem
                label={intl.formatMessage({ id: 'GOAL_SUMMARY_PAGE.TARGET' })}
                value={goalDetails?.goalAmount}
                currency={'KES'}
              />
              <Divider className="gray-divider" />

              <GoalSummaryItem
                label={intl.formatMessage({
                  id: 'GOAL_SUMMARY_PAGE.MONTHLY_AMOUNT',
                })}
                value={goalDetails?.recurringAmount}
                currency={'KES'}
              />
              <Divider className="gray-divider" />

              {goalDetails?.years || goalDetails?.months ? (
                <>
                  <GoalSummaryItem
                    label={intl.formatMessage({
                      id: 'GOAL_SUMMARY_PAGE.TARGET_PERIOD',
                    })}
                    value={displayTargetPeriod()}
                  />
                </>
              ) : null}
              <Divider className="gray-divider" />
              <GoalSummaryItem
                label={intl.formatMessage({ id: 'GOAL_SUMMARY_PAGE.END_DATE' })}
                value={goalDetails?.targetDate}
              />
              <Divider className="gray-divider" />

              <GoalSummaryItem
                label={intl.formatMessage({
                  id: 'GOAL_SUMMARY_PAGE.INITIAL_AMOUNT',
                })}
                value={moneyFormat(goalDetails?.initialInvestment)}
                currency={'KES'}
              />
            </div>

            <div className="goal-summary-container mt-5">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <div className="font-size-20 red-text">
                  {intl.formatMessage({ id: 'GOAL_SUMMARY_PAGE.BALANCED' })}
                </div>
                <div className="bg-red info-circle d-flex justify-content-center align-items-center white-text font-weight-bolder font-size-14">
                  ?
                </div>
              </div>
              <Divider className="gray-divider" />
            </div>

            <CustomCheckBox
              setAcceptTermsAndCondition={setAcceptTermsAndCondition}
              acceptTermsAndCondition={acceptTermsAndCondition}
            />

            <div className="d-flex justify-content-between my-10">
              <Button
                buttonText={intl.formatMessage({ id: 'BUTTONS.BACK' })}
                classes={'bg-red-400 white-text font-size-20 px-14 py-5'}
                link="/portfolio/goal/calculator"
                onClick={createTheGoal}
              />

              <Button
                buttonText={intl.formatMessage({ id: 'BUTTONS.INVEST_NOW' })}
                classes={'bg-red white-text font-size-20 p-5'}
                buttonType="submit"
                onClick={() => setOpenTransactionDetailsModal(true)}
                disabled={!acceptTermsAndCondition}
              />
            </div>

            <TextModal
              open={openTransactionDetailsModal}
              setOpen={setOpenTransactionDetailsModal}
              text={
                <TransactionDetailsContent
                  setOpenTransactionDetailsModal={
                    setOpenTransactionDetailsModal
                  }
                  setOpenConfirmationModal={setOpenConfirmationModal}
                  goalPayload={goalDetails}
                  onClick={createTheGoal}
                />
              }
            />

            <TextModal
              open={openConfirmationModal}
              setOpen={setOpenConfirmationModal}
              text={
                <ConfirmPayment
                  setOpenConfirmationModal={setOpenConfirmationModal}
                />
              }
            />

            {/* {payWithMpesa ? (
              <PayWithMpesa setPayWithMpesa={setPayWithMpesa} />
            ) : null} */}
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, { ...goalActions.actions })(GoalSummary)
);
