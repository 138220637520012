import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const LOGIN_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/login`;
const REGISTER_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/register`;
const REQUEST_PASSWORD_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/request-reset-password`;
const REQUEST_PASSWORD_CONFIRMATION_URL = `${REACT_APP_BASE_URL}/v1/investor/auth/password-reset-confirmation`;
const REQUEST_EMAIL_VERIFICATION = `${REACT_APP_BASE_URL}/v1/investor/auth/verify/`;
const REQUEST_RESEND_EMAIL_VERIFICATION = `${REACT_APP_BASE_URL}/v1/investor/auth/resend-verification`;
const GENERATE_ACCESS_TOKEN_MAIL = `${REACT_APP_BASE_URL}/v1/investor/auth/generate-2f-token`;
const SEND_ACCESS_TOKEN = `${REACT_APP_BASE_URL}/v1/investor/auth/validate-2f-token`;
const GET_EXTERNAL_USER = `${REACT_APP_BASE_URL}/v1/investor/auth/external-user-token`;

const login = async (email, password) => {
  const res = await axios.post(LOGIN_URL, { email, password });
  return res;
};

const register = async (email, firstname, lastname, password) => {
  const res = await axios.post(REGISTER_URL, {
    email,
    firstName: firstname,
    lastName: lastname,
    password,
  });
  return res;
};

const requestPassword = async (email) => {
  const response = await axios.post(REQUEST_PASSWORD_URL, { email });
  return response;
};

const requestPasswordConfirmation = async (id, password, passwordResetCode) => {
  const response = await axios.put(REQUEST_PASSWORD_CONFIRMATION_URL, {
    id,
    password,
    passwordResetCode,
  });
  return response;
};

const requestEmailVerification = async (id, code) => {
  const response = await axios.post(`${REQUEST_EMAIL_VERIFICATION}${id}`, {
    code,
  });
  return response;
};

const requestResendEmailVerification = async (email) => {
  const response = await axios.post(REQUEST_RESEND_EMAIL_VERIFICATION, {
    email,
  });
  return response;
};

const generateAccessTokenAndSendMail = async () => {
  const response = await axios.post(GENERATE_ACCESS_TOKEN_MAIL);
  return response;
};

const sendAccessToken = async (token) => {
  const response = await axios.post(SEND_ACCESS_TOKEN, { token });
  return response;
};

const getExternalUser = async (redirectionCode) => {
  const res = await axios.post(GET_EXTERNAL_USER, { redirectionCode });
  return res;
};

export {
  login,
  register,
  requestPassword,
  requestPasswordConfirmation,
  requestEmailVerification,
  requestResendEmailVerification,
  generateAccessTokenAndSendMail,
  sendAccessToken,
  getExternalUser,
};
