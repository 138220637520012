import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Congratulations from '../Common/Congratulations';
import Calendar from '../Reminder/pages/Calendar';
import WeeklyDays from '../Reminder/pages/WeeklyDays';
import WithdrawAmount from './components/WithdrawAmountModal';

import Investments from './pages/Investments';
import ViewGoal from './pages/ViewGoal';

const Portfolio = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}`} component={Investments} />
        <Route exact path={`${match.path}/viewGoal/:id`} component={ViewGoal} />
        <Route
          exact
          path={`${match.path}/goal/withdraw`}
          component={WithdrawAmount}
        />

        <Route
          exact
          path={`${match.path}/reminder/day`}
          component={WeeklyDays}
        />

        <Route
          exact
          path={`${match.path}/reminder/calendar`}
          component={Calendar}
        />

        <Route
          exact
          path={`${match.path}/congratulations`}
          component={Congratulations}
        />
      </Switch>
    </>
  );
};

export default Portfolio;
