import React from 'react';
import { Popover, OverlayTrigger, PopoverContent } from 'react-bootstrap';

const PopoverComponent = ({ children, popoverContent }) => {
  const popover = (
    <Popover id="popover-basic">
      <PopoverContent>{popoverContent}</PopoverContent>
    </Popover>
  );
  return (
    <OverlayTrigger
      trigger={['click', 'focus']}
      placement="top"
      overlay={popover}
      rootClose={true}
    >
      {children}
    </OverlayTrigger>
  );
};

export default PopoverComponent;
