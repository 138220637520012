import React from 'react';
import { injectIntl } from 'react-intl';

const DisplayProfit = ({ intl, icon, profit, currency }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="font-weight-bold font-size-12">
        {intl.formatMessage({
          id: 'PORTFOLIO.RETURN',
        })}{' '}
        <span className="green-text-600">
          {profit} {currency}
        </span>
      </div>
      <div className="ml-2">
        <img src={icon} alt="Small Triangle Icon" />
      </div>
      <div className="ml-2 white-text font-size-14 bg-red d-flex justify-content-center align-items-center border-radius-full question-mark-container">
        ?
      </div>
    </div>
  );
};

export default injectIntl(DisplayProfit);
