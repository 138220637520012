import dashboardIcon from '../../../../../app/icons/MobileMenuIcons/homeRedIcon.svg';
import dashboardWhiteIcon from '../../../../../app/icons/MobileMenuIcons/homeWhiteIcon.svg';
import goalsRedIcon from '../../../../../app/icons/MobileMenuIcons/goalsRedIcon.svg';
import goalsWhiteIcon from '../../../../../app/icons/MobileMenuIcons/goalsWhiteIcon.svg';
import portfolio from '../../../../../app/icons/MobileMenuIcons/goalsRedIcon.svg';
import portfolioWhiteIcon from '../../../../../app/icons/MobileMenuIcons/portfolioWhiteIcon.svg';

const BottomMenuItems = [
  {
    title: 'Dashboard',
    name: 'dashboard',
    id: 'bottom-dashboard',
    path: '/dashboard',
    icon: { white: dashboardWhiteIcon, red: dashboardIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Goals',
    name: 'newInvestment',
    id: 'bottom-invest',
    path: '/portfolio',
    icon: { white: goalsWhiteIcon, red: goalsRedIcon },
    active: false,
    hover: false,
  },
  {
    title: 'Risk Profile',
    name: 'portfolio',
    id: 'portfolio',
    path: '/risk-profile',
    icon: { white: portfolioWhiteIcon, red: portfolio },
    active: false,
    hover: false,
  },
];

export default BottomMenuItems;
