import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { currencyHelper } from '../../../helpers/currencyHelper';
import SliderInput from '../../Common/SliderInput';
import TopUpImage from '../../../icons/topUpImage.svg';
import { Button } from '../../Common/AllButtons';

const WithdrawAmount = ({
  open,
  setOpenWidthdrawModal,
  title,
  fundValue,
  currency,
  minWithdrawableAmount,
  setOpenConfirmModal,
  intl,
}) => {
  const [withdrawAmount, setWithdrawAmount] = useState(0);
  const [withdrawPerc, setWithdrawPerc] = useState(0);
  const [minWithdrawAmountAchieved, setMinWithdrawAmountAchieved] = useState(
    false
  );
  const [withdrawPercentage, setwithdrawPercentage] = useState(0);

  const { goalDetails } = useSelector((state) => state.goal);

  const handleAmountChange = (event) => {
    let { value } = event.target;
    value = Number(value);
    setWithdrawPerc(value);
    setwithdrawPercentage(value);
    const withdrawAmount = (value * fundValue?.toFixed(2)) / 100;
    setWithdrawAmount(withdrawAmount);

    if (withdrawAmount >= minWithdrawableAmount) {
      setMinWithdrawAmountAchieved(true);
    } else {
      setMinWithdrawAmountAchieved(false);
    }
  };

  const setOpenConfirmationModal = () => {
    setOpenWidthdrawModal(false);
    setOpenConfirmModal(true);
  };

  const displayError = () => {
    const subString = '* minimum withdrawable amount is ';

    if (withdrawPerc && !minWithdrawAmountAchieved) {
      return (
        <div className="my-5 withdrw-modal-err">
          {subString}
          {currencyHelper(minWithdrawableAmount, currency)}
        </div>
      );
    }
  };

  const body = (
    <>
      {displayError()}
      <div className="mt-8 bg-light-blue border-radius-16 px-5 py-15">
        <div className="text-center">
          <img src={TopUpImage} alt="Top Up" />
        </div>
        <div className="mt-5 text-center font-size-33">Withdraw</div>

        <SliderInput
          handleChange={handleAmountChange}
          title={`How much would you like to withdraw from your ${goalDetails?.name}?`}
          rangeInputName="withdrawPerc"
          sliderValue={withdrawPerc}
          silderLabels={
            <div className="d-flex justify-content-between mx-auto">
              <p>{`0 ${currency}`}</p>
              <p>{currencyHelper(fundValue, currency)}</p>
            </div>
          }
          min={0}
          max={100}
          step={10}
          input={
            <div className="d-flex justify-content-center mx-auto">
              <div className="goal-amount text-center">
                {currencyHelper(withdrawAmount, currency)}
              </div>
              <div className="goal-amount text-center">{withdrawPerc} %</div>
            </div>
          }
        />

        <div className="mt-5">
          <Button
            buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
            link={'#'}
            classes={'w-100 bg-red white-text font-size-20 py-5'}
          />

          <Button
            buttonText={intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
            classes={'w-100 bg-red-100 red-text font-size-20 px-10 py-5 mt-5'}
            link={`/portfolio/viewGoal/${goalDetails?.id}`}
          />
        </div>
      </div>
    </>
  );
  return <>{body}</>;
};

export default injectIntl(WithdrawAmount);
