import React from 'react';
import './closeModal.scss';

const CloseModal = ({ setOpen, setSuccessMessage, setErrorMessage }) => {
  return (
    <span
      className="close-icon"
      onClick={() => {
        setSuccessMessage(null);
        setErrorMessage(null);
        setOpen(false);
      }}
    >
      x
    </span>
  );
};

export default CloseModal;
