import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { period } from '../../../helpers/constants';
import { dates, days } from '../../../helpers/investmentHelpers';
import { moneyFormat } from '../../../helpers/moneyFormat';
import Alert from '../../Common/Alert';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { setupReminder } from '../_redux/reminders';

const Calendar = ({ intl }) => {
  const [selectedDate, setSelectedDate] = useState();
  const history = useHistory();
  const [alert, setAlert] = useState();

  const { goalDetails } = useSelector((state) => state.goal);

  const handleSetupReminder = async () => {
    try {
      const response = await setupReminder(
        Number(selectedDate),
        period.MONTHLY,
        goalDetails?.identifier
      );
      if (response?.data?.data?.id) {
        history.push('/portfolio');
      }
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'REMINDERS_PAGE.TITLE' })}
        previousPage="/portfolio/congratulations"
        insideContainer
      />
      <div className="container min-vh-100 bg-white mt-28">
        {alert?.alertMessage ? <Alert alert={alert} /> : null}
        <div className="font-size-17 text-center mt-5 mb-10">
          {intl.formatMessage({ id: 'REMINDERS_PAGE.MONTHLY.QUESTION' })}
        </div>
        <div className="d-flex flex-wrap mt-5 bg-light-blue border-radius-16 px-3 py-5">
          {days.map((date) => (
            <div
              className={`${
                selectedDate === date
                  ? 'white-text bg-red border-radius-full'
                  : ''
              } font-size-20 date-container d-flex justify-content-center align-items-center cursor-pointer`}
              key={date}
              onClick={() => setSelectedDate(date)}
            >
              {date}
            </div>
          ))}
        </div>

        {selectedDate ? (
          <div className="font-size-17 text-center mt-5">
            {intl.formatMessage(
              { id: 'REMINDERS_PAGE.MONTHLY.DESCRIPTION' },
              { selectedDate: dates[selectedDate] }
            )}{' '}
            <span className="red-text">
              {goalDetails?.currency}{' '}
              {moneyFormat(goalDetails?.recurringAmount)}
            </span>{' '}
          </div>
        ) : null}

        {selectedDate ? (
          <Button
            buttonText={'FINISH'}
            classes={'bg-red white-text font-size-17 w-100 py-5 my-10'}
            onClick={() => {
              handleSetupReminder();
            }}
          />
        ) : null}
      </div>
    </>
  );
};
export default injectIntl(Calendar);
