export const actionTypes = {
  FetchLearningVideos: '[FetchLearningVideos] Action',
};

const initialState = {};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FetchLearningVideos: {
      const { payload } = action;
      return {
        ...state,
        learningVideos: payload,
      };
    }

    default:
      return state;
  }
};

export const actions = {
  fetchLearningVideos: (learningVideos) => ({
    type: actionTypes.FetchLearningVideos,
    payload: learningVideos,
  }),
};

export function* saga() {}
