import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as goal from '../app/modules/Goal/_redux/goalReducers';
import * as learningVideos from '../app/modules/Dashboard/_redux/dashboardReducers';

import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  goal: goal.reducer,
  learningVideos: learningVideos.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    learningVideos.saga(),
  ]);
}
