import React from 'react';
import { injectIntl } from 'react-intl';

const CustomCheckBox = ({
  acceptTermsAndCondition,
  setAcceptTermsAndCondition,
  intl,
}) => {
  return (
    <label className="container mt-5">
      <span className="ml-5">
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.CHECKBOX.TERMS_AND_CONDITIONS_1',
        })}{' '}
        <span className="red-text">
          <a
            href={'https://ndovu.co/ndovu-terms-and-conditions/'}
            target="_blank"
            className="red-text"
          >
            {intl.formatMessage({
              id: 'GOAL_SUMMARY_PAGE.CHECKBOX.TERMS_AND_CONDITIONS_2',
            })}
          </a>{' '}
        </span>{' '}
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.CHECKBOX.TERMS_AND_CONDITIONS_3',
        })}
      </span>
      <input
        className="custom-checkbox"
        type="checkbox"
        checked={acceptTermsAndCondition}
        onChange={() => setAcceptTermsAndCondition(!acceptTermsAndCondition)}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default injectIntl(CustomCheckBox);
