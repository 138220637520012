import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import * as goalActions from '../../Goal/_redux/goalReducers';

import { fetchGoals, deleteEmptyGoal } from '../_redux/goalActions';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import SingleGoal from '../components/SingleGoal';
import ConfirmModal from '../../Common/ConfirmModal';

import NoIinvestmentMade from '../components/NoInvestmentMade';
import { PageTitle } from '../../Common/PageTitle';
import { Button } from '../../Common/AllButtons';
import NewGoalIcon from '../../../icons/newGoalIcon.svg';
import { connect } from 'react-redux';

const Investments = ({ intl, updateGoalDetails }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [goals, setGoals] = useState([]);
  const [waiting, setWaiting] = useState(true);
  const [open, setOpen] = useState(false);
  const [goalId, setGoalId] = useState();

  const fetchAllGoals = async () => {
    try {
      setLoading(true);
      const res = await fetchGoals();
      if (res.status === 200) {
        setGoals(res.data.data);
      }
      setWaiting(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllGoals();
    updateGoalDetails(null);
  }, []);

  const handleDeleteGoal = async () => {
    setLoading(true);
    await deleteEmptyGoal(goalId);
    const res = await fetchGoals();
    if (res.status === 200) {
      setGoals(res.data.data);
      setLoading(false);
      setOpen(false);
    }
  };

  const handAddGoalButton = () => {
    localStorage.setItem('previousPage', '/portfolio');
    updateGoalDetails(null);
    history.push('/portfolio/goal/select-goal');
  };

  if (loading || waiting) {
    return (
      <div className="text-center" style={{ marginTop: '100px' }}>
        <img
          style={{ width: '70px' }}
          src={toAbsoluteUrl('/media/logos/loading.gif')}
          alt={'loading gif'}
        />
      </div>
    );
  }
  return (
    <>
      <PageTitle title={'Portfolio'} insideContainer />
      <div className="mt-25">
        <ConfirmModal
          open={open}
          setOpen={setOpen}
          confirm={handleDeleteGoal}
          text="Are you sure you want to delete this goal?"
          title="Delete a goal"
        />
        {goals?.length === 0 && !waiting ? (
          <NoIinvestmentMade />
        ) : (
          <>
            <Button
              buttonText={intl.formatMessage({
                id: 'DASHBOARD.ADD_GOAL',
              })}
              classes={'bg-red white-text font-size-17 px-5 py-3'}
              onClick={handAddGoalButton}
              buttonIcon={NewGoalIcon}
            />
            {goals?.map((goal) => (
              <SingleGoal
                setGoals={setGoals}
                setGoalId={setGoalId}
                setOpen={setOpen}
                key={goal.id}
                {...goal}
              />
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default injectIntl(
  connect(null, { ...goalActions.actions })(Investments)
);
