import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const CREATE_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals`;
const GOAL_TOPUP_URL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;
const INVESTMENT_IN_A_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;
const MPESA_GOAL_INVESTMENT = `${REACT_APP_BASE_URL}/v1/investor/mpesa/goal-investment`;
const FETCH_GOAL_NAMES = `${REACT_APP_BASE_URL}/v1/investor/goals/suggested-goal-names`;

const createGoal = async (
  goalName,
  goalAmount,
  currency,
  targetDate,
  monthlyAmount,
  initialInvestmentAmount,
  investmentProfile,
  goalId
) => {
  const res = await axios.post(CREATE_GOAL, {
    name: goalName,
    goalAmount,
    currency,
    targetDate,
    recurringAmount: monthlyAmount,
    initialInvestment: initialInvestmentAmount,
    investmentProfileId: investmentProfile,
    staticGoalId: goalId,
  });
  return res;
};

const goalTopUp = async (goalId, txReference, channel, transactionId) => {
  const res = await axios.post(`${GOAL_TOPUP_URL}${goalId}/goalTopUp`, {
    txReference,
    channel,
    transactionId,
  });
  return res;
};

const investmentInGoal = async (
  goalId,
  txReference,
  channel,
  transactionId
) => {
  const res = await axios.post(`${INVESTMENT_IN_A_GOAL}${goalId}/investment`, {
    txReference,
    channel,
    transactionId,
  });
  return res;
};

/**
 * Handle goal investment using MPESA
 * @param {number} id Identification of the goal
 * @param {string} phoneNumber user's phone number to charge
 * @param {string} amount Amount to pay with no promo code applied
 * @param { enum } type type of investment <INITIAL_INVESTMENT | TOP_UP>
 * @param {string} promoCode promotion code if any. It should be null.
 */
const goalInvestmentWithMPESA = async (
  id,
  phoneNumber,
  amount,
  type,
  promoCode
) => {
  return axios.post(MPESA_GOAL_INVESTMENT, {
    id: parseInt(id),
    phoneNumber,
    amount,
    promoCode,
    type,
  });
};

const getGoalNames = async () => {
  const res = await axios.get(FETCH_GOAL_NAMES);

  return res;
};

export {
  createGoal,
  goalTopUp,
  investmentInGoal,
  goalInvestmentWithMPESA,
  getGoalNames,
};
