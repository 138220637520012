import React from 'react';
import { injectIntl } from 'react-intl';
import { moneyFormat } from '../../../../helpers/moneyFormat';
import { Button } from '../../../Common/AllButtons';

const TransactionDetailsContent = ({
  setOpenTransactionDetailsModal,
  goalPayload,
  intl,
  onClick,
}) => {
  return (
    <div>
      <div className="text-center font-size-17">
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.TRANSACTION_DETAILS_MODAL.TITLE',
        })}
      </div>
      <div className="gray-divider mt-6 mb-4" />
      <div className="d-flex justify-content-between font-size-14">
        <div>
          {intl.formatMessage({
            id: 'GOAL_SUMMARY_PAGE.TRANSACTION_DETAILS_MODAL.DEPOSIT',
          })}
        </div>
        <div>
          <span className="red-text">{'KES'}</span>{' '}
          {moneyFormat(goalPayload?.initialInvestment)}
        </div>
      </div>
      <div className="gray-divider my-4" />

      <div className="d-flex justify-content-between font-size-14">
        <div>
          {intl.formatMessage({
            id: 'GOAL_SUMMARY_PAGE.TRANSACTION_DETAILS_MODAL.FEE',
          })}
        </div>
        <div>
          <span className="red-text">{'KES'}</span> 5{' '}
        </div>
      </div>
      <div className="gray-divider my-4" />

      <div className="d-flex justify-content-between font-size-14">
        <div>
          {intl.formatMessage({
            id: 'GOAL_SUMMARY_PAGE.TRANSACTION_DETAILS_MODAL.PAYABLE',
          })}
        </div>
        <div>
          <span className="red-text">{'KES'}</span>{' '}
          {goalPayload?.initialInvestment
            ? moneyFormat(Number(goalPayload?.initialInvestment) + 5)
            : '---'}
        </div>
      </div>
      <div className="gray-divider mt-4" />

      <div className="d-flex justify-content-between mt-10">
        <Button
          buttonText={intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
          classes={'bg-black-600 white-text font-size-17 px-10 py-5'}
          onClick={() => setOpenTransactionDetailsModal(false)}
          //   onClick={createTheGoal}
        />

        <Button
          buttonText={intl.formatMessage({ id: 'BUTTONS.CONFIRM' })}
          classes={'bg-black-600 white-text font-size-17 px-10 py-5'}
          buttonType="submit"
          //   onClick={createTheGoal}
          onClick={() => {
            onClick();
          }}
        />
      </div>
    </div>
  );
};

export default injectIntl(TransactionDetailsContent);
