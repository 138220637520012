import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../_metronic/_helpers';
import { Button } from '../../Common/AllButtons';

const LearnCard = ({ videos, intl }) => {
  const [videoId, setVideoId] = useState('');
  const [show, setShow] = useState(false);
  const history = useHistory();

  let videoUrl =
    'https://www.youtube.com/embed/' +
    videoId +
    '?autoplay=1;mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent';
  const handleHide = () => setShow(false);
  const handleShow = (videoLink) => {
    setVideoId(videoLink);
    setShow(true);
  };
  return (
    <>
      {videos.length ? (
        <div className="videos-title mt-5">Learn Investing</div>
      ) : null}
      <div className="fund-plans mt-5">
        {videos?.slice(0, 2).map((video) => {
          return (
            <div className="d-flex flex-column learn-card mb-5" key={video.id}>
              <div
                className="learn-card d-flex flex-row"
                style={{
                  backgroundImage: 'url(' + video.thumbnail + ')',
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                }}
              >
                <div
                  className="learn-card-play"
                  onClick={() => handleShow(video.videoId)}
                >
                  <div className="triangle"></div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-3">
                <div className="">
                  <img
                    src={toAbsoluteUrl('/media/logos/air-invest.svg')}
                    alt="Circle Ndovu"
                    className="pill-icon"
                  />
                </div>
                <span className="learn-card-title ml-2">{video.title}</span>
              </div>
            </div>
          );
        })}

        <Modal
          show={show}
          onHide={handleHide}
          centered="true"
          className="learning-modal"
        >
          <iframe
            width="100%"
            height="100%"
            src={videoUrl}
            frameBorder="0"
            allow="autoplay"
            allowFullScreen
          ></iframe>
        </Modal>
      </div>
      {videos.length >= 2 ? (
        <div className="mt-3">
          <Button
            buttonText={intl.formatMessage({ id: 'BUTTONS.MORE_VIDEOS' })}
            link={'https://ndovu.co/ndovu-academy/'}
            classes={
              'w-75 mx-auto d-flex justify-content-center bg-red white-text font-size-20 py-5'
            }
            externalLink
          />
        </div>
      ) : null}
    </>
  );
};

export default injectIntl(LearnCard);
