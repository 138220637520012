import React, { Fragment } from 'react';

import { Link } from 'react-router-dom';

export const WizardNextAndPreviousButton = ({
  classes,
  action,
  btnText,
  loading,
  type,
}) => {
  return (
    <button
      type={type ? type : 'button'}
      className={`btn  font-weight-bolder text-uppercase px-20 py-5 mb-2 ${classes} `}
      onClick={action}
    >
      {btnText}
      {loading && <span className="ml-5 spinner spinner-white"></span>}
    </button>
  );
};

export const WizardButton = ({
  action,
  link,
  type,
  btnText,
  loading,
  classes,
}) => {
  return (
    <Fragment>
      {link ? (
        <Link to={link}>
          <WizardNextAndPreviousButton
            classes={classes}
            action={action}
            btnText={btnText}
            loading={loading}
            type={type}
          />
        </Link>
      ) : (
        <WizardNextAndPreviousButton
          classes={classes}
          action={action}
          btnText={btnText}
          loading={loading}
          type={type}
        />
      )}
    </Fragment>
  );
};

export const SubmitButton = ({ disabled, BtnText, classes, loading }) => {
  return (
    <button
      type="submit"
      className={`btn font-weight-bolder ${classes} px-9 py-4`}
      style={{ color: '#fff', background: '#0071CE' }}
      disabled={disabled}
    >
      {BtnText ? BtnText : 'Submit'}
      {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};

export const CancelButton = ({
  disabled,
  BtnText,
  classes,
  onClick,
  loading,
}) => {
  return (
    <button
      onClick={onClick}
      type="button"
      className={`${classes} cancel-btn-padding`}
      style={{ color: '#0071CE', background: '#CAE2F6' }}
      disabled={disabled}
    >
      {BtnText ? BtnText : 'Cancel'}
      {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};

export const OrangeInvestButton = ({
  handleOnClick,
  InvestAndSaveIcon,
  buttonText,
}) => {
  return (
    <button
      onClick={() => handleOnClick()}
      className="px-5 py-3 btn invest-button-orange"
    >
      <img src={InvestAndSaveIcon} alt={'Invest and save icon'} />
      <span className="ml-3">{buttonText}</span>
    </button>
  );
};
