import React from 'react';

const CommonButton = ({
  icon,
  alt,
  className,
  btnText,
  onClick,
  type,
  loading,
  disabled,
}) => {
  return (
    <button
      type={type ? type : 'button'}
      className={className}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <img className="mr-3" src={icon} alt={alt} />}
      {btnText}
      {loading && <span className="ml-3 spinner spinner-white" />}
    </button>
  );
};

export default CommonButton;
