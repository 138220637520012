import React from 'react';

export const GoalSummaryItem = ({ label, value, currency }) => {
  return (
    <div className="d-flex justify-content-between my-3 font-size-15">
      <div>{label}</div>
      <div className="goal-values">{`${
        currency ? currency : ''
      } ${value}`}</div>
    </div>
  );
};
