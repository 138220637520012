import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import Alert from '../../Common/Alert';
import Loader from '../../Common/Loader';
import { getExternalUser } from '../_redux/authCrud';

const ExternalUsers = ({ intl }) => {
  const { redirectionCode } = useParams();
  const [alert, setAlert] = useState({
    alertMessage: null,
    alertMessageType: null,
  });

  useEffect(() => {
    const getUserToken = async () => {
      try {
        const response = await getExternalUser(redirectionCode);
        localStorage.setItem('ndovuToken', response.data.accessToken);
          window.location.replace('/welcome');
      } catch (error) {
        setAlert({ alertMessage: error.response.data.message });
      }
    };

    getUserToken();
  }, []);
  return (
    <div className="d-flex justify-content-center align-items-center vh-100 flex-column font-size-15">
      {alert?.alertMessage ? <Alert alert={alert} /> : null}
      {!alert?.alertMessage ? (
        <>
          <div>{intl.formatMessage({id: "WELCOME_PAGE.WELCOME_TEXT"})}</div>
          <Loader />
        </>
      ) : null}
    </div>
  );
};

export default injectIntl(ExternalUsers);
