import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

import './index.scss';
import CloseModal from '../../../Common/CloseModal';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, 15%)`,
    outline: 'none',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '5px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    [theme.breakpoints.down(768)]: {
      width: '100%',
    },
  },
}));

const TextModal = ({ open, setOpen, confirm, text, title }) => {
  const [modalStyle] = useState(getModalStyle());
  const classes = useStyles();

  const body = (
    <div style={modalStyle} className={classes.paper}>
      {title ? (
        <div>
          <div className="d-flex justify-content-between">
            <h6 id="simple-modal-title">{title}</h6>
            <CloseModal setOpen={setOpen} />
          </div>
          <div className="divider"></div>
        </div>
      ) : null}

      {text}
    </div>
  );
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default TextModal;
