import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const SETUP_REMINDER = `${REACT_APP_BASE_URL}/v1/investor/reminders`;

const setupReminder = async (value, period, goalReference) => {
  const res = await axios.post(`${SETUP_REMINDER}/${goalReference}`, {
    value,
    period,
  });
  return res;
};

export { setupReminder };
