import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_GOALS = `${REACT_APP_BASE_URL}/v1/investor/goals`;
const DELETE_EMPTY_GOAL_URL = `${REACT_APP_BASE_URL}/v1/investor/goals`;
const FETCH_GOAL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;
const FETCH_SUGGESTED_PLAN_DETAILS = `${REACT_APP_BASE_URL}/v1/investor/plans/pie-chart/all`;
const GOAL_WITHDRAL_URL = `${REACT_APP_BASE_URL}/v1/investor/goals/`;

const fetchGoals = async () => {
  const res = await axios.get(FETCH_GOALS);
  return res;
};

const deleteEmptyGoal = async (goalId) => {
  const response = await axios.delete(`${DELETE_EMPTY_GOAL_URL}/${goalId}`);

  return response;
};

const fetchSingleGoal = async (goalId) => {
  const res = await axios.get(`${FETCH_GOAL}${goalId}`);
  return res;
};

const goalWithdraw = async (goalId) => {
  const response = await axios.post(`${GOAL_WITHDRAL_URL}${goalId}/withdraw`);
  return response;
};

export { fetchGoals, deleteEmptyGoal, fetchSingleGoal, goalWithdraw };
