import React from 'react';
import packageJson from '../../package.json';

global.appVersion = packageJson.version;

class InvalidateCache extends React.Component {
  state = {
    loading: true,
    isLatestVersion: false,
    refreshCacheAndReload: async () => {
      console.log('🎊 Clearing cache and hard reloading...');
      //uninstall all registered service worker
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });

      // Remove cache entries
      await caches
        .keys()
        .then(async (names) =>
          Promise.all(names.map((name) => caches.delete(name)))
        );

      // Hard reload after a second of clearing the cache
      setTimeout(() => window.location.reload(), 1000);
    },
  };

  // version from `meta.json` - first param
  // version in bundle file - second param
  areVersionNotEqual = (versionA, versionB) => versionA !== versionB;

  componentDidMount() {
    fetch('/meta.json', { cache: 'no-cache' })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = global.appVersion;

        const shouldForceRefresh = this.areVersionNotEqual(
          latestVersion,
          currentVersion
        );

        if (shouldForceRefresh) {
          console.log(`🎊 Reload new version - ${latestVersion}`);
          this.setState({ loading: false, isLatestVersion: false });
        } else this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    });
  }
}

export default InvalidateCache;
