import React from 'react';

export const Day = ({ day, handleSelect, selectedDay }) => {
  return (
    <div
      className={`${
        selectedDay?.name === day?.name ? 'bg-red white-text' : 'bg-light-blue'
      } pl-5 py-5 mt-4 border-radius-16 font-size-17`}
      onClick={() => handleSelect(day)}
    >
      {day.name}
    </div>
  );
};
