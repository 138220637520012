import React from 'react';
import { injectIntl } from 'react-intl';

import airInvest from '../../../images/airinvest.svg';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';

const HowItWorks = ({ intl }) => {
  return (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: 'INSTRUCTIONS.HOW_IT_WORKS_PAGE.TITLE',
        })}
        previousPage="/welcome"
        insideContainer
      />
      <div className="container text-center mt-30 min-vh-100">
        <img src={airInvest} alt="airInvest" />
        <div className="red-text-color font-weight-bold font-size-15 mt-10">
          {intl.formatMessage({ id: 'INSTRUCTIONS.HOW_IT_WORKS_PAGE.HEADER' })}
        </div>
        <p className="how-it-works-text mt-10 font-weight-bold font-size-15">
          {intl.formatMessage({
            id: 'INSTRUCTIONS.HOW_IT_WORKS_PAGE.DESCRIPTION_A',
          })}
        </p>

        <p className="how-it-works-text font-weight-bold font-size-15">
          {intl.formatMessage({
            id: 'INSTRUCTIONS.HOW_IT_WORKS_PAGE.DESCRIPTION_B',
          })}
        </p>

        <Button
          classes="bg-red white-text py-5 w-75 mt-10"
          buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
          link="/setup-your-goal"
        />
        <div className="d-flex justify-content-center mt-5">
          <div className="page-indicator red-dot"></div>
          <div className="page-indicator gray-dot ml-2"></div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(HowItWorks);
