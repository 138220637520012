export const messages = {
  pendingTransactions: {
    totalInvested:
      'Pending transactions. The total invested amount will be updated once these transactions are processed.',
    currentValue:
      'Pending transactions. The current value amount will be updated once these transactions are processed.',
    title: 'Transactions in progress',
  },

  currentValue:
    'Pending transactions. The current value will be updated once these transactions are processed.',

  fundWithdraw: {
    title: 'Withdraw',
    body:
      'Please note by completing this action you are withdrawing from this fund.  The withdrawal amount displayed is an approximate value. There is no fee charged by ndovu for the withdrawal.',
    unpprovedWithdraw:
      'You have unapproved withdrawal request on this fund. Please wait before making a new investment.',
  },

  goalWithdraw: {
    title: 'Withdraw',
    body:
      'Please note that by completing this action, you are withdrawing the total amount invested in this goal and not a proportion of your investment. There is no fee charged by ndovu for the withdrawal.',
    unpprovedWithdraw:
      'You have unapproved withdrawal request on this goal. Please wait before making a new investment.',
  },

  annualPerformance:
    'Annual performance (%) - this is how much return the fund has generated over a year.',
  annualPerformanceSavingProduct:
    'This is the fixed return earned per year. TAX FREE',

  cashDescription: 'Cash holdings like money market fund and fixed deposits',
  equityDescription: 'Equity also known as shares and stock',
  bondDescription:
    'Bonds holdings like government debt and high quality corporate',
  approximativeWithdrawnAmountInTransaction:
    'The withdrawal amount is indicative and may vary once the trade has been processed due to market movements.',

  savingFundAlert:
    'You can always withdraw your money within the lock up period. However, please note that you will not earn any interest on the funds withdrawn.',
};

export const ProfitType = {
  VARIABLE: 'VARIABLE',
  FIX_SIMPLE: 'FIX_SIMPLE',
  FIX_COMPOUND: 'FIX_COMPOUND',
};
