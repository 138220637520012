import React from 'react';

const RangeSlider = ({
  handleSlideChange,
  sliderValue,
  min,
  max,
  step,
  rangeInputName,
}) => {
  return (
    <div className="slidecontainer mx-auto slider-input-container">
      <input
        type="range"
        name={rangeInputName}
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        className="slider slider-input-container"
        id="myRange"
        style={{
          backgroundSize: ((sliderValue - min) * 100) / (max - min) + '% 100%',
        }}
        onChange={handleSlideChange}
        autoComplete="off"
      />
    </div>
  );
};

export default RangeSlider;
