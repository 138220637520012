import axios from 'axios';
import dotenv from 'dotenv';

dotenv.config();

const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

const FETCH_LEARNING_VIDEOS = `${REACT_APP_BASE_URL}/v1/investor/learning/youtube-videos`;

export const fetchLearningVideos = async () => {
  const res = await axios.get(`${FETCH_LEARNING_VIDEOS}`);
  return res;
};
