import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

import * as goal from '../_redux/goalReducers';

// import Input from '../../../Common/Input/ControlledInput';
// import Button from '../../../Common/Button';
import Input from '../../Common/Input/ControlledInput';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';

const NewGoal = ({ updateGoalDetails, intl }) => {
  const [goalPayload, setGoalPayload] = useState();
  const { goalDetails } = useSelector((state) => state.goal);

  useEffect(() => {
    setGoalPayload(goalDetails);
  }, []);

  let history = useHistory();

  const GoalNameSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setGoalPayload({
      ...goalPayload,
      [name]: value,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { name: goalPayload?.name },
    validationSchema: GoalNameSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      if (values !== '') {
        setSubmitting(true);
        updateGoalDetails({ ...goalDetails, name: values?.name });
        localStorage.setItem('previousPage', '/portfolio/goal/new');
        history.push('/portfolio/goal/calculator');
      } else {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'GOAL_NAME_PAGE.TITLE' })}
        previousPage={'/portfolio/goal/select-goal'}
        insideContainer
      />
      <div className="card card-custom card-gutter-b mt-15">
        <div className="card-body">
          <p className="text-center mt-5">Give your financial goal a name…</p>
          <form className="mt-5" id="kt_form" onSubmit={formik.handleSubmit}>
            {/* <!--begin: Wizard Step 1--> */}

            {/* <!--begin::Form Group--> */}
            <div className="form-group fv-plugins-icon-container">
              <div className="mb-2 form-group">
                <div className="input-container">
                  <Input
                    placeholder="Goal Name(E.g. car, new house)"
                    type="text"
                    className={`form-control input-field ${getInputClasses(
                      'name'
                    )}`}
                    name="name"
                    formik={formik}
                    defaultValue={goalPayload?.name || ''}
                    handleChange={handleChange}
                  />
                </div>
              </div>
              {formik.touched.name && formik.errors.name ? (
                <div className="input-container fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.name}</div>
                </div>
              ) : null}
            </div>
            {/* <!--end::Form Group--> */}

            <div className="mb-10 text-center mt-10 mb-lg-5">
              <Button
                buttonText={intl.formatMessage({ id: 'BUTTONS.NEXT' })}
                classes={'w-100 bg-red white-text font-size-20 py-5'}
                buttonType="submit"
              />
            </div>
          </form>
          {/* <!--end::Form--> */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(connect(mapStateToProps, goal.actions)(NewGoal));
