import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import NewGoalIcon from '../../../icons/newGoalIcon.svg';
import { Button } from '../../Common/AllButtons';

const NoIinvestmentMade = ({ intl }) => {
  const history = useHistory();
  return (
    <div className="pt-5 text-center bg-light-blue border-radius-16 pb-10">
      <p className="mx-auto mt-8" style={{ width: '246px', fontSize: '14px' }}>
        {intl.formatMessage({ id: 'PORTFOLIO.NO_INVESTMENT_MESSAGE' })}
      </p>

      <Button
        buttonText={intl.formatMessage({
          id: 'DASHBOARD.ADD_GOAL',
        })}
        classes={'bg-red white-text font-size-17 px-5 py-3'}
        onClick={() => history.push('/portfolio/goal/select-goal')}
        buttonIcon={NewGoalIcon}
      />
    </div>
  );
};

export default injectIntl(NoIinvestmentMade);
