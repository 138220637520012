/* eslint-disable jsx-a11y/anchor-is-valid */

import '../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss';
import '../styles/registration.scss';

import { Redirect, Switch } from 'react-router-dom';

import AfterRegMessage from './AfterRegMessage';
import { ContentRoute } from '../../../../_metronic/layout';
import Login from './Login';
import React from 'react';
import Registration from './Registration';
import { useHistory } from 'react-router-dom';

export const AuthPage = () => {
  const history = useHistory();
  history.listen((location, action) => {});

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid"
          id="kt_login"
          style={{ backgroundColor: '#fff' }}
        >
          {/*begin::Content*/}
          <div className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
            <div className="d-flex flex-column-fluid flex-center mt-5 mt-lg-15">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/success"
                  component={AfterRegMessage}
                />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
};
