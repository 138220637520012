import React from 'react';

const UnableViewPassword = ({ handleClickEvent, viewPassword, classes }) => {
  return (
    <div onClick={handleClickEvent} className={`${classes}`}>
      {viewPassword ? (
        <i className="fa fa-eye" aria-hidden="true"></i>
      ) : (
        <i className="fa fa-eye-slash" aria-hidden="true"></i>
      )}
    </div>
  );
};

export default UnableViewPassword;
