import React from 'react';
import RangeSlider from './sliderRanger';

const SliderInput = ({
  input,
  handleChange,
  title,
  silderLabels,
  min,
  max,
  step,
  rangeInputName,
  sliderValue,
}) => {
  return (
    <div className="mx-auto px-lg-10 px-sm-3 text-center pt-3">
      <div className="text-center mb-5 mx-3 titles">{title}</div>
      {input}

      <div className="mt-4">
        <RangeSlider
          handleSlideChange={handleChange}
          sliderValue={sliderValue || 1}
          min={min}
          max={max}
          step={step}
          rangeInputName={rangeInputName}
        />
      </div>
      {silderLabels}
    </div>
  );
};

export default SliderInput;
