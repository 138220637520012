import React from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';

const DashboardCard = ({
  imgSrc,
  title,
  profit,
  label,
  amount,
  link,
  className,
}) => {
  const history = useHistory();
  return (
    <div
      className={`d-flex justify-content-between align-items-center bg-light-blue border-radius-16 w-100 px-10 py-5 ${className}`}
      onClick={() => history.push(link)}
    >
      <div>
        <div className="font-size-16">{title}</div>
        {amount ? <div className="font-size-30 red-text">{amount}</div> : null}
        {profit || label ? (
          <div className="font-size-16">
            {profit ? profit : label ? label : null}
          </div>
        ) : null}
      </div>
      <img
        className="dashboard-image-mobile"
        src={imgSrc}
        alt={`${title} icon`}
      />
    </div>
  );
};

export default injectIntl(DashboardCard);
