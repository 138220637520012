import React from 'react';

const InputValidationErrorMessage = ({ message }) => {
  return (
    <div style={{ color: '#F05B7F' }} className="mt-1 input-container">
      <span>{message}</span>
    </div>
  );
};

export default InputValidationErrorMessage;
