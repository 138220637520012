import React from "react";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

const Loader = () => {
  return (
    <div className="text-center">
      <img
        style={{ width: "80px" }}
        src={toAbsoluteUrl("/media/logos/loading.gif")}
        alt={"loading gif"}
      />
    </div>
  );
};

export default Loader;
