import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import objectPath from 'object-path';
import { useHtmlClassService } from '../../_core/MetronicLayout';

import angleDoubleLeft from '../../../../app/icons/angleDoubleLeft.svg';
import AirInvestLogo from '../../../../app/images/AirInvestLogo.svg';

export function Brand({ profile }) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        'aside.self.minimize.toggle'
      ),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    };
  }, [uiService]);

  const checkCorporateCode =
    profile?.voucherCorporate && profile.voucherCorporate.logoLink !== null;

  const brandContainerClasses = 'flex-column-auto brand-container';
  const closeLeftMenuAside = () => {
    let asideItemElement = document.getElementById('kt_body');
    asideItemElement.classList?.contains('aside-minimize')
      ? asideItemElement.classList.remove('aside-minimize')
      : asideItemElement.classList.add('aside-minimize');
  };

  const displayLogo = () => {
    if (profile?.originPartner?.logoUrl) {
      return (
        <img
          alt="logo"
          src={profile?.originPartner?.logoUrl}
          className="ndovu-logo"
        />
      );
    } else if (checkCorporateCode) {
      return (
        <img
          alt="logo"
          src={profile.voucherCorporate.logoLink}
          className="ndovu-logo"
        />
      );
    } else {
      return <img alt="logo" src={AirInvestLogo} className="ndovu-logo" />;
    }
  };

  return (
    <>
      {/* begin::Brand */}
      {!profile?.loading ? (
        <div
          className={
            checkCorporateCode || profile?.originPartner?.logoUrl
              ? `partner-brand ${brandContainerClasses} ${layoutProps.brandClasses}`
              : `air-invest-brand bg-red px-5 ${brandContainerClasses} ${layoutProps.brandClasses}`
          }
          id="kt_brand"
        >
          {/* begin::Logo */}
          <Link
            to=""
            className={`${
              checkCorporateCode ? 'partner-brand-logo' : 'brand-logo'
            }`}
          >
            {displayLogo()}
          </Link>
          {/* end::Logo */}

          {layoutProps.asideSelfMinimizeToggle && (
            <>
              {/* begin::Toggle */}
              <button
                className="brand-toggle btn btn-sm px-0 active"
                id="kt_aside_toggle"
                onClick={closeLeftMenuAside}
              >
                <span className="svg-icon svg-icon-xl">
                  <img src={angleDoubleLeft} alt="left arrow" />
                </span>
              </button>
              {/* end::Toolbar */}
            </>
          )}
        </div>
      ) : (
        <div className="loading-logo"></div>
      )}
      {/* end::Brand */}
    </>
  );
}
