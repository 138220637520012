import React from 'react';
import PinInput from 'react-pin-input';

export const PinInputComponent = () => {
  return (
    <PinInput
      length={4}
      initialValue=""
      secret
      onChange={(value, index) => {}}
      type="numeric"
      inputMode="number"
      style={{ padding: '10px' }}
      inputStyle={{ borderColor: 'red' }}
      inputFocusStyle={{ borderColor: 'blue' }}
      onComplete={(value, index) => {}}
      autoSelect={true}
      regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
    />
  );
};
