import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect, Switch } from 'react-router-dom';
import { ContentRoute, LayoutSplashScreen } from '../_metronic/layout';
import * as goal from './modules/Goal/_redux/goalReducers';
import Loader from './modules/Common/Loader';
import Dashboard from './modules/Dashboard/pages/dashboard';
import Portfolio from './modules/Portfolio/index';

import { BuilderPage } from './pages/BuilderPage';
import SessionTimeout from './utils/sessionTimeout';
import { useClearCache } from 'react-clear-cache';
import Goal from './modules/Goal';

import HowItWorks from './modules/Instructions/Pages/HowItWorks';
import SetupYourGoal from './modules/Instructions/Pages/SetupYourGoal';
import WelcomePage from './modules/Instructions/Pages/WelcomePage';

const BasePage = () => {
  const [waiting, setWaiting] = useState(true);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SessionTimeout isAuthenticated={true} />
      {waiting ? (
        <div className="mx-auto my-auto">
          <Loader />
        </div>
      ) : (
        <Switch>
          <ContentRoute path="/dashboard" component={Dashboard} />
          <ContentRoute path="/welcome" component={WelcomePage} />
          <ContentRoute path="/how-it-works" component={HowItWorks} />
          <ContentRoute path="/setup-your-goal" component={SetupYourGoal} />
          {/* TODO: To be removed */}
          <ContentRoute path="/portfolio/goal" component={Goal} />
          <ContentRoute path="/portfolio" component={Portfolio} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <Redirect to="error/error-v1" />
        </Switch>
      )}
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
});
export default connect(mapStateToProps, {
  ...goal.actions,
})(BasePage);
