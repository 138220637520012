import React from 'react';

export default function CheckBox({ formik, children, label, name, path }) {
  return (
    <div className="form-group">
      <label className="cursor-pointer d-flex">
        <input
          type="checkbox"
          name={name}
          className="mt-1 mr-2"
          {...formik.getFieldProps(`${name}`)}
        />
        <span>{label}</span>
        {path && (
          <span className="ml-2 font-weight-bold" style={{ color: '#0071CE' }}>
            <a
              style={{ color: '#0071CE' }}
              href={path}
              target="_blank"
              rel="noopener noreferrer"
            >
              (View)
            </a>
          </span>
        )}
      </label>
      <span />
      {children}
    </div>
  );
}
