import React from 'react';
import { Route, Switch } from 'react-router-dom';
import GoalCalculator from './pages/GoalCalculator';
import GoalSummary from './pages/GoalSummary';
import NewGoal from './pages/NewGoal';
import GoalTopupAmount from './pages/GoalTopupAmount';
import GoalNames from './components/GoalNames';
// import PayWithMpesa from '../Invest/components/PayWithMpesa';

const Goal = ({ match }) => {
  return (
    <>
      <Switch>
        <Route exact path={`${match.path}/new`} component={NewGoal} />
        <Route exact path={`${match.path}/select-goal`} component={GoalNames} />
        <Route
          exact
          path={`${match.path}/calculator`}
          component={GoalCalculator}
        />
        <Route exact path={`${match.path}/summary`} component={GoalSummary} />

        <Route
          exact
          path={`${match.path}/topup/:goalId`}
          component={GoalTopupAmount}
        />

        {/* <Route
          exact
          path={`${match.path}/pay-with-mpesa`}
          component={PayWithMpesa}
        /> */}
      </Switch>
    </>
  );
};

export default Goal;
