import * as Yup from 'yup';
import * as goal from '../_redux/goalReducers';

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';

// import InvestmentAmount from '../../Invest/InvestmentAmount';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import { moneyFormat } from '../../../helpers/moneyFormat';
import TextModal from '../components/LearnMore';
// import PayWithMpesa from '../../Invest/components/PayWithMpesa';
import TransactionDetailsModalContent from '../components/TransactionDetailsModalContent';
import ConfirmPaymentModal from '../components/ConfirmPaymentModal';

const TopupAmount = (props) => {
  const { intl } = props;
  const { goalId } = useParams();
  let history = useHistory();
  const [goalData, setGoalData] = useState();
  const [
    openTransactionDetailsModal,
    setOpenTransactionDetailsModal,
  ] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [payWithMpesa, setPayWithMpesa] = useState();

  const { goalDetails } = useSelector((state) => state.goal);

  const initialValues = {
    amount: goalData?.amount,
    currency: goalData?.currency,
  };

  useEffect(() => {
    const localStorageData = JSON.parse(
      localStorage.getItem('goalDataForPayment')
    );

    if (localStorageData?.goalId) {
      setGoalData(localStorageData);
    }
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    const numbersRegex = /^[0-9,]*$/;
    if (name === 'amount' && numbersRegex.test(value)) {
      setGoalData({
        ...goalData,
        amount: moneyFormat(convertAmountToNumber(value)),
      });
    } else {
      setGoalData({
        ...goalData,
        currency: value,
      });
    }
  };

  const GoalAmountSchema = Yup.object().shape({
    amount: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: GoalAmountSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (values.amount !== '') {
        const { amount, currency } = values;
        props.updateGoalDetails({
          initialInvestment: convertAmountToNumber(amount),
          currency,
          id: Number(goalId),
          targetDate: goalDetails?.targetDate,
          goalAmount: goalDetails?.goalAmount,
          recurringAmount: goalDetails?.recurringAmount,
        });
        setSubmitting(true);
        localStorage.getItem('action', 'TOP_UP');
        history.push(`/goal/topup/summary`);
      } else {
        setSubmitting(false);
      }
    },
  });

  const handleNext = () => {
    localStorage.getItem('action', 'TOP_UP');
    const { amount, currency } = goalData;
    props.updateGoalDetails({
      initialInvestment: convertAmountToNumber(amount),
      currency,
      id: Number(goalId),
      targetDate: goalDetails?.targetDate,
      goalAmount: goalDetails?.goalAmount,
      recurringAmount: goalDetails?.recurringAmount,
    });

    setOpenTransactionDetailsModal(true);
  };

  const handleSubmit = async () => {
    setOpenTransactionDetailsModal(false);
    history.push(`/portfolio/goal/pay-with-mpesa`);
    setPayWithMpesa(true);
    return;
  };

  return (
    <>
      {/* <InvestmentAmount
        url={`/goal/summary`}
        formik={formik}
        handleChange={handleChange}
        goalData={goalData}
        titleText={intl.formatMessage({
          id: 'GOAL_TOP_UP_PAGE.TOP_UP',
        })}
        description={`${intl.formatMessage({
          id: 'GOAL_TOP_UP_PAGE.INPUT_LABEL',
        })} ${goalDetails?.name ? goalDetails?.name : '--'} ?`}
        onClick={() => handleNext()}
      /> */}

      <TextModal
        open={openTransactionDetailsModal}
        setOpen={setOpenTransactionDetailsModal}
        text={
          <TransactionDetailsModalContent
            setOpenTransactionDetailsModal={setOpenTransactionDetailsModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            goalPayload={goalDetails}
            onClick={handleSubmit}
          />
        }
      />

      <TextModal
        open={openConfirmationModal}
        setOpen={setOpenConfirmationModal}
        text={
          <ConfirmPaymentModal
            setOpenConfirmationModal={setOpenConfirmationModal}
          />
        }
      />

      {/* {payWithMpesa ? <PayWithMpesa setPayWithMpesa={setPayWithMpesa} /> : null} */}
    </>
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(connect(mapStateToProps, goal.actions)(TopupAmount));
