import React from 'react';
import { injectIntl } from 'react-intl';
import SecurityLock from '../../../../icons/securityLock.svg';
import { Button } from '../../../Common/AllButtons';
import { PinInputComponent } from '../../../Common/PinInput';

const ConfirmPayment = ({ setOpenConfirmationModal, intl }) => {
  return (
    <div className="text-center font-size-14">
      <img src={SecurityLock} alt="Security Lock Icon" />
      <div className="my-5">
        {' '}
        {/* TODO: USE DYNAMIC VALUE */}
        <span className="red-text font-weight-bolder">KES</span>{' '}
        <span className="font-weight-bolder">500</span>{' '}
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.CONFIRMATION_MODAL.DEDUCTED_AMOUNT',
        })}
      </div>

      <div>
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.CONFIRMATION_MODAL.ENTER_PIN',
        })}
      </div>
      <div>
        {intl.formatMessage({
          id: 'GOAL_SUMMARY_PAGE.CONFIRMATION_MODAL.BALANCE',
        })}
        <span className="red-text">KES</span> 55000
      </div>

      <div className="mt-4">
        <PinInputComponent />
        <small className="blue-text mt-3">
          {intl.formatMessage({
            id: 'GOAL_SUMMARY_PAGE.CONFIRMATION_MODAL.FORGOT_PIN',
          })}
        </small>
      </div>

      <div className="d-flex justify-content-between mt-10">
        <Button
          buttonText={intl.formatMessage({ id: 'BUTTONS.CANCEL' })}
          classes={'bg-black-600 white-text font-size-17 px-10 py-5'}
          onClick={() => setOpenConfirmationModal(false)}
          //   onClick={createTheGoal}
        />

        <Button
          buttonText={intl.formatMessage({ id: 'BUTTONS.CONFIRM' })}
          classes={'bg-black-600 white-text font-size-17 px-10 py-5'}
          buttonType="submit"
          //   onClick={createTheGoal}
          onClick={() => setOpenConfirmationModal(false)}
        />
      </div>
    </div>
  );
};

export default injectIntl(ConfirmPayment);
