import '../styles/registration.scss';

import * as Yup from 'yup';
import * as auth from '../_redux/authRedux';

import { SubmitButton } from '../../Common/buttons/Buttons';
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import CheckBox from '../../Common/CheckBox';
import Input from '../../Common/Input/Input';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { register } from '../_redux/authCrud';
import { useFormik } from 'formik';
import UnableViewPassword from '../../Common/UnableViewPassword';
import {
  passwordRegex,
  passwordValidationMessage,
  letterNumberRegex,
  specialCharError,
} from '../../../helpers/constants';
import { marketingSiteLinks } from '../../../utils/marketingSiteLinks';

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
};

const Registration = (props) => {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [registerSuccess, setRegisterSuccess] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);
  const [viewConfirmPassword, setViewConfirmPassword] = useState(false);
  let history = useHistory();
  const RegistrationSchema = Yup.object().shape({
    firstname: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(letterNumberRegex, specialCharError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    lastname: Yup.string()
      .min(2, 'Minimum 2 symbols')
      .max(50, 'Maximum 50 symbols')
      .matches(letterNumberRegex, specialCharError)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    password: Yup.string()
      .matches(passwordRegex, passwordValidationMessage)
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: 'AUTH.VALIDATION.REQUIRED_FIELD',
        })
      )
      .when('password', {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          "Password and Confirm Password didn't match"
        ),
      }),
    acceptTerms: Yup.bool().required(
      'You must accept the terms and conditions'
    ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid';
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid';
    }

    return '';
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        enableLoading();
        const response = await register(
          values.email.toLowerCase(),
          values.firstname,
          values.lastname,
          values.password
        );
        disableLoading();
        setSubmitting(false);
        if (response.status === 201) {
          setRegisterSuccess(true);
          setStatus('Registration is successful, verify your email to login.');
          history.push('/auth/success');
        }
      } catch (error) {
        setSubmitting(false);
        setStatus(error.response.data.message);
        disableLoading();
      }
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: 'block' }}>
      <div className="mb-10 text-center mb-lg-10">
        <h1 className="font-size-h1">Sign up</h1>
        <p>Enter your details to create an account</p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && registerSuccess === true ? (
          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : null}

        {formik.status && !registerSuccess ? (
          <div className="mb-10">
            <div
              className="text-center font-weight-bold"
              style={{ color: '#F05B7F' }}
            >
              {formik.status}
            </div>
          </div>
        ) : null}
        {/* end: Alert */}

        {/* begin: First name */}
        <div className="input-spacing fv-plugins-icon-container">
          <Input
            placeholder="First name"
            type="text"
            className={`form-control input-field ${getInputClasses(
              'firstname'
            )}`}
            name="firstname"
            formik={formik}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.firstname}</div>
            </div>
          ) : null}
        </div>
        {/* end: First name */}

        {/* begin: Lastname */}
        <div className="input-spacing fv-plugins-icon-container">
          <Input
            placeholder="Last name"
            type="text"
            className={`form-control input-field ${getInputClasses(
              'lastname'
            )}`}
            name="lastname"
            formik={formik}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.lastname}</div>
            </div>
          ) : null}
        </div>
        {/* end: Last name */}

        {/* begin: Email */}
        <div className="input-spacing fv-plugins-icon-container">
          <Input
            placeholder="Email"
            type="email"
            className={`form-control input-field ${getInputClasses('email')}`}
            name="email"
            formik={formik}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="password-spacing fv-plugins-icon-container">
          <Input
            placeholder="Password"
            type={viewPassword ? 'text' : 'password'}
            className={`form-control input-field ${getInputClasses(
              'password'
            )}`}
            name="password"
            formik={formik}
          />
          <UnableViewPassword
            handleClickEvent={() => setViewPassword(!viewPassword)}
            viewPassword={viewPassword}
            classes="password-eye-icon"
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="error-message-spacing fv-help-block">
                {formik.errors.password}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}
        {/* <div className="padding-top-password"></div> */}

        {/* begin: Confirm Password */}
        <div
          style={{
            marginTop:
              formik.touched.password && formik.errors.password && '-10px',
          }}
          className="password-spacing fv-plugins-icon-container"
        >
          <Input
            placeholder="Confirm Password"
            type={viewConfirmPassword ? 'text' : 'password'}
            className={`form-control input-field ${getInputClasses(
              'changepassword'
            )}`}
            name="changepassword"
            formik={formik}
          />
          <UnableViewPassword
            handleClickEvent={() =>
              setViewConfirmPassword(!viewConfirmPassword)
            }
            viewPassword={viewConfirmPassword}
            classes="password-eye-icon"
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="error-message-spacing fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <CheckBox
          name="acceptTerms"
          formik={formik}
          label="I agree the Terms & Conditions"
          path={marketingSiteLinks.termsAndConditions}
        >
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </CheckBox>
        {/* end: Terms and Conditions */}
        <div className="flex-wrap form-group d-flex flex-center justify-content-between mobile-row">
          <Link to="/auth/login" className="btn-mobile">
            <button
              type="button"
              className={`btn nextBtn font-weight-bolder text-capitalize py-4 px-9`}
              style={{
                color: '#0071CE',
                background: '#CAE2F6',
              }}
            >
              {'Cancel'}
            </button>
          </Link>
          <SubmitButton
            classes="btn-mobile space-top"
            BtnText="Sign up"
            loading={loading}
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
          />
        </div>
      </form>
    </div>
    // </div>
  );
};

export default injectIntl(connect(null, auth.actions)(Registration));
