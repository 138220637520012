import React from 'react';
import RangeSlider from '../../../Common/sliderRanger';

const SliderInput = ({
  input,
  handleChange,
  title,
  silderLabels,
  min,
  max,
  step,
  rangeInputName,
  sliderValue,
  titleb,
}) => {
  return (
    <div className="mx-auto px-3 text-center goal-amount-slider-container pt-3 border-radius-16">
      <div className="text-center font-weight-bold m-3 titles">
        {title}{' '}
        {titleb ? <span className="font-weight-bolder">{titleb}</span> : null}
      </div>
      <div className="px-3">{input}</div>

      <div className="mt-3">
        <RangeSlider
          handleSlideChange={handleChange}
          sliderValue={sliderValue || 1}
          min={min}
          max={max}
          step={step}
          rangeInputName={rangeInputName}
        />
      </div>
      <div className="mt-5 mx-5">{silderLabels}</div>
    </div>
  );
};

export default SliderInput;
