import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';

import * as goalActions from '../../Goal/_redux/goalReducers';

import { daysOfTheWeek, period } from '../../../helpers/constants';
import Alert from '../../Common/Alert';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';
import { Day } from '../components/Day';
import { setupReminder } from '../_redux/reminders';
import { useHistory } from 'react-router-dom';
import { moneyFormat } from '../../../helpers/moneyFormat';
import { injectIntl } from 'react-intl';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';

const WeekDays = ({ intl }) => {
  const history = useHistory();
  const [selectedDay, setSelectedDay] = useState();
  const [alert, setAlert] = useState();

  const { goalDetails } = useSelector((state) => state.goal);

  const handleSetupReminder = async () => {
    try {
      const response = await setupReminder(
        selectedDay?.value,
        period.WEEKLY,
        goalDetails?.identifier
      );
      if (response?.data?.data?.id) {
        history.push('/portfolio');
      }
    } catch (error) {
      setAlert({
        alertMessage: error.response.data.message,
        alertMessageType: 'error',
      });
    }
  };
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'REMINDERS_PAGE.TITLE' })}
        previousPage="/portfolio/congratulations"
        insideContainer
      />
      <div className="container mt-28">
        {alert?.alertMessage ? <Alert alert={alert} /> : null}
        <div className="font-size-17 text-center mt-5 mb-10">
          {intl.formatMessage({ id: 'REMINDERS_PAGE.WEEKLY.QUESTION' })}
        </div>
        {daysOfTheWeek.map((day) => (
          <Day
            key={day.name}
            day={day}
            handleSelect={setSelectedDay}
            selectedDay={selectedDay}
          />
        ))}

        {selectedDay ? (
          <div className="font-size-15 text-center mt-5">
            {` Every ${selectedDay?.name} you will be reminded to pay`}{' '}
            {intl.formatMessage(
              { id: 'REMINDERS_PAGE.WEEKLY.DESCRIPTION' },
              { selectedDay: selectedDay?.name }
            )}{' '}
            <span className="red-text">
              {goalDetails?.currency}{' '}
              {moneyFormat(
                convertAmountToNumber(goalDetails?.recurringAmount) / 4.32
              )}
            </span>{' '}
          </div>
        ) : null}

        <Button
          buttonText={'FINISH'}
          classes={'bg-red white-text font-size-17 w-100 py-5 my-10'}
          onClick={() => {
            handleSetupReminder();
          }}
        />
      </div>
    </>
  );
};

export default injectIntl(connect(null, { ...goalActions.actions })(WeekDays));
