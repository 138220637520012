export const currencyHelper = (amount, currency, mathSign = null, lang) => {
  const twoDecimalDigit = amount?.toLocaleString(lang ? lang : 'en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const money = `${twoDecimalDigit} ${currency?.toUpperCase()}`;

  return mathSign ? `${mathSign} ${money}` : money;
};
