import React from 'react';
import { Link } from 'react-router-dom';

import elephant from '../../../images/congrats.svg';
import { SubmitButton } from '../../Common/buttons/Buttons';

const AfterRegMessage = () => {
  return (
    <>
      <div>
        <div className="text-center">
          <img src={elephant} alt="Elephant" />
          <h3 className="my-5 font-size-h4" style={{ color: '#0071CE' }}>
            Thank you for choosing ndovu
          </h3>
          <div className="font-weight-bold">
            <p className="px-lg-20 px-md-20">
              Within the next hour, a verification email will be sent to your
              specified email address.{' '}
              <strong>Please check spam, junk or promotion folders.</strong>{' '}
            </p>
            {/* <p>Thank you for choosing Ndovu.</p> */}
            <div>
              {/* TODO: REACTIVATE 'RESEND VER...' BUTTON ONCE FAILING EMAILS ARE FIXED */}

              {/* <Link
                to="/auth/resendVerification"
                className="btn resend-verification-btn font-weight-bold px-9 py-4 my-8"
              >
                Resend Verification Email
              </Link> */}

              <Link to="/auth/signin">
                <SubmitButton
                  BtnText="Sign in"
                  classes="btn-mobile space-top"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AfterRegMessage;
