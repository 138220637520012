import React from 'react';
import { injectIntl } from 'react-intl';
import { currencyHelper } from '../../../helpers/currencyHelper';

const GoalAmount = ({ estimatedBalance, currency, goalAmount, intl }) => {
  return (
    <div className="d-flex justify-content-between font-weight-bold font-size-13">
      <div>
        <div>{intl.formatMessage({ id: 'PORTFOLIO.GOAL_CURRENT_AMOUNT' })}</div>
        <div className="red-text">
          {estimatedBalance
            ? currencyHelper(estimatedBalance, currency)
            : `0 ${currency}`}
        </div>
      </div>
      <div>
        <div>{intl.formatMessage({ id: 'PORTFOLIO.GOAL_TARGET_AMOUNT' })}</div>
        <div className="red-text">
          {goalAmount ? currencyHelper(goalAmount, currency) : `0 ${currency}`}
        </div>
      </div>
    </div>
  );
};

export default injectIntl(GoalAmount);
