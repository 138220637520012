import React from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageSelectorDropdown } from '../../../../_metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';

export const PageTitle = ({
  title,
  previousPage,
  insideContainer,
  leftIcon,
  languageSwitcher,
}) => {
  const history = useHistory();

  return (
    <div
      className={`container d-flex align-items-center justify-content-between py-5 bg-red position-fixed z-index-8 ${
        insideContainer ? 'margin-left--15 margin-top--5' : null
      }`}
    >
      {leftIcon ? (
        <img src={leftIcon} alt="AirInvest Logo" />
      ) : previousPage ? (
        <div
          onClick={() => {
            localStorage.removeItem('previousPage');
            history.push(previousPage);
          }}
        >
          <i className="fa fa-arrow-left white-text"></i>
        </div>
      ) : null}
      <div className="white-text w-100 text-center font-size-20 font-weight-bold">
        {title}
      </div>

      {languageSwitcher ? (
        <div className="mr-5 ml-3">
          <LanguageSelectorDropdown />
        </div>
      ) : null}
    </div>
  );
};
