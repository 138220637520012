import dotenv from 'dotenv';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import * as goal from '../_redux/goalReducers';
import InputValidationErrorMessage from '../../Common/InputValidationErrorMessage';
import MessageAlert from '../../Common/MessageAlert';
import SliderInput from '../components/GoalCalculator/SliderInput';
import convertAmountToNumber from '../../../helpers/convertAmountToNumber';
import '../index.scss';
import { moneyFormat } from '../../../helpers/moneyFormat';
import DisplayCorrectErrorMessage from '../components/GoalCalculator/DisplayCorrectErrorMessage';
import { findCurrency } from '../../../helpers/findCurreny';
import { Button } from '../../Common/AllButtons';
import { currencyHelper } from '../../../helpers/currencyHelper';
import { PageTitle } from '../../Common/PageTitle';
import { getTargetDate } from '../../../helpers/investmentHelpers';

dotenv.config();

const maxYears = 8;
const numbersRegex = /^[0-9,]*$/;

const GoalCalculator = (props) => {
  const {
    profile: { riskProfile: investorRiskProfile, riskProfileLevels },
    conversions: { conversions },
    currencies: { currencies: allCurrencies },
  } = useSelector((state) => state);
  const [goalPayload, setGoalPayload] = useState({
    currency: 'KES',
    years: 0,
    months: 0,
    goalAmount: 0,
  });
  const [months, setMonths] = useState(1);
  const [years, setYears] = useState(0);
  const [targetDateError, setTargetDateError] = useState(null);
  const [backendError, setBackendError] = useState(null);
  const { goalDetails } = useSelector((state) => state.goal);

  const { intl } = props;
  let history = useHistory();

  const initialValues = {
    initialAmount: goalPayload?.initialAmount,
    goalAmount: goalPayload?.goalAmount,
    currency: goalPayload?.currency,
  };

  useEffect(() => {
    setGoalPayload({
      ...goalPayload,
      initialAmount:
        goalDetails?.initialInvestment &&
        moneyFormat(goalDetails?.initialInvestment),
      goalAmount: goalDetails?.recurringAmount && goalDetails?.recurringAmount,
      currency: goalDetails?.currency ? goalDetails?.currency : 'KES',
    });
    goalDetails?.years && setYears(goalDetails?.years);
    goalDetails?.months && setMonths(goalDetails?.months);
  }, []);

  const GoalAmountSchema = Yup.object().shape({
    goalAmount: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
    currency: Yup.string().required(
      intl.formatMessage({
        id: 'AUTH.VALIDATION.REQUIRED_FIELD',
      })
    ),
  });

  const handleYearChange = (event) => {
    const { value, id } = event.target;
    if (!numbersRegex.test(value)) {
      return false;
    }
    if (id === 'years') {
      if (value >= maxYears) {
        setYears(maxYears);
        setMonths(0);
      } else {
        setYears(Number(value));
      }
    }

    if (id === 'months') {
      if (years >= maxYears) {
        setMonths(0);
      } else if (value >= 12) {
        const numberOfYears = Number((value / 12).toString().split('.')[0]);
        const totalYears = Number(years) + numberOfYears;
        setYears(totalYears >= maxYears ? maxYears : totalYears);
        setMonths(Number(value % 12));
      } else {
        setMonths(Number(value));
      }
    }
  };

  const generateMaximumAmount = (currency) => {
    const foundCurrency = findCurrency(allCurrencies, currency);
    const amountDetails = {
      amount: foundCurrency?.max,
      amountWithCommaAndCurrency: `${moneyFormat(
        Number(foundCurrency?.max)
      )} ${currency}`,
    };

    return amountDetails;
  };

  const generateMinimumAmount = (currency) => {
    const foundCurrency = findCurrency(allCurrencies, currency);

    const amountDetails = {
      amount: foundCurrency?.min,
      amountWithCommaAndCurrency: `${moneyFormat(
        Number(foundCurrency?.min)
      )} ${currency}`,
    };

    return amountDetails;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    const convertTonumber = Number(value);
    if (!numbersRegex.test(value)) {
      return false;
    }
    if (name === 'targetDate') {
      setYears(
        Number(
          Number(convertTonumber / 12)
            .toString()
            .split('.')[0]
        )
      );
      setMonths(Number(convertTonumber % 12));
      setTargetDateError(null);
    } else if (name === 'initialAmount' || name === 'goalAmount') {
      setGoalPayload({
        ...goalPayload,
        [name]: moneyFormat(convertAmountToNumber(value)),
      });
    } else {
      setGoalPayload({
        ...goalPayload,
        [name]: value,
      });
    }
  };

  const updateAmount = (minimumInvestment, maxInvestment, amount, name) => {
    setGoalPayload({
      ...goalPayload,
      [name]:
        convertAmountToNumber(amount) > 0 &&
        convertAmountToNumber(amount) < Number(minimumInvestment)
          ? moneyFormat(Number(minimumInvestment))
          : convertAmountToNumber(amount) >= maxInvestment
          ? moneyFormat(maxInvestment)
          : moneyFormat(convertAmountToNumber(amount)),
    });
  };

  const handleInvestingNowAmountChange = (event) => {
    const { name, value } = event.target;
    if (!numbersRegex.test(value.slice(-1))) {
      return false;
    }

    const foundCurrency = findCurrency(allCurrencies, goalPayload.currency);
    updateAmount(foundCurrency?.min, foundCurrency?.max, value, name);
  };

  const monthlyAmountUpdate = (maxAmount, amount, name) => {
    setGoalPayload({
      ...goalPayload,
      [name]:
        convertAmountToNumber(amount) >= maxAmount
          ? moneyFormat(maxAmount)
          : moneyFormat(convertAmountToNumber(amount)),
    });
  };

  const handleAmountChange = (event) => {
    const { name, value } = event.target;
    if (!numbersRegex.test(value.slice(-1))) {
      return false;
    }
    const foundCurrency = findCurrency(allCurrencies, goalPayload.currency);
    return monthlyAmountUpdate(foundCurrency?.max, value, name);
  };

  const generateRiskProfileLevel = () => {
    const riskProfile =
      riskProfileLevels &&
      riskProfileLevels.find(
        (riskLevel) =>
          riskLevel.id ===
          Number(
            goalDetails?.investmentProfile?.riskProfile?.id ||
              investorRiskProfile?.id
          )
      );
    return riskProfile;
  };

  const goalAmountNumber = goalPayload?.goalAmount
    ? convertAmountToNumber(goalPayload?.goalAmount)
    : 0;
  const initialAmountNumber = convertAmountToNumber(goalPayload?.initialAmount);

  const interestRate = generateRiskProfileLevel()?.avgYTD / 100;

  // compound-interest-formula
  // Total = [ Compound interest for principal ] + [ Future value of a series ]
  // Total = [ P(1+r/n)^(nt) ] + [ PMT × (((1 + r/n)^(nt) - 1) / (r/n)) ]
  // https://www.thecalculatorsite.com/articles/finance/compound-interest-formula.php

  const totalMonths = years * 12 + months;
  const compoundInterestForPrincipal =
    initialAmountNumber * Math.pow(1 + interestRate / 12, totalMonths);

  const futureValueOfSeries =
    goalAmountNumber *
    ((Math.pow(1 + interestRate / 12, totalMonths) - 1) / (interestRate / 12));

  const totalTargetAmount = Number(
    compoundInterestForPrincipal + futureValueOfSeries
  ).toFixed(2);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: GoalAmountSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (years * 12 + months < 1) {
        setTargetDateError('Time is required');
        return;
      }
      if (values !== '') {
        props.updateGoalDetails({
          ...goalDetails,
          targetDate: getTargetDate(years, months),
          currency: goalPayload?.currency,
          goalAmount: moneyFormat(totalTargetAmount),
          initialInvestment: convertAmountToNumber(goalPayload?.initialAmount),
          investmentProfile: goalPayload?.riskLevel,
          name: goalDetails?.name,
          id: goalDetails?.id,
          recurringAmount: goalPayload?.goalAmount,
          years,
          months,
        });
        localStorage.setItem('action', 'INITIAL_INVESTMENT');
        history.push('/portfolio/goal/summary');
      } else {
        setSubmitting(false);
      }
    },
  });

  const previousPageUrl = localStorage.getItem('previousPage');

  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'GOAL_CALCULATOR_PAGE.TITLE' })}
        previousPage={
          previousPageUrl ? previousPageUrl : '/portfolio/goal/select-goal'
        }
        insideContainer
      />
      <div className="mt-18">
        {backendError ? (
          <MessageAlert
            closeAlert={() => setBackendError(null)}
            alert={backendError}
          />
        ) : null}
        {(totalTargetAmount && totalTargetAmount > 0) ||
        (goalDetails?.goalAmount &&
          convertAmountToNumber(goalDetails?.goalAmount) > 0) ? (
          <div className="font-size-20 position-fixed z-index-1 bg-white w-100 text-center py-5 margin-left--15">
            {intl.formatMessage({
              id: 'GOAL_CALCULATOR_PAGE.TARGET_AMOUNT_LABEL',
            })}
            :{' '}
            <span className="red-text">
              {totalTargetAmount && totalTargetAmount > 0
                ? currencyHelper(
                    Number(totalTargetAmount),
                    goalPayload?.currency
                  )
                : currencyHelper(
                    convertAmountToNumber(goalDetails?.goalAmount),
                    goalDetails?.currency
                  )}
            </span>{' '}
          </div>
        ) : null}
        <form id="kt_form" onSubmit={formik.handleSubmit}>
          <div className="d-flex flex-column">
            <div className="font-weight-bold">
              <div
                className={
                  (totalTargetAmount && totalTargetAmount > 0) ||
                  (goalDetails?.goalAmount &&
                    convertAmountToNumber(goalDetails?.goalAmount) > 0)
                    ? 'mt-25'
                    : 'mt-10'
                }
              >
                <SliderInput
                  formik={formik}
                  handleChange={handleAmountChange}
                  title={intl.formatMessage({
                    id: 'GOAL_CALCULATOR_PAGE.LABEL.INITIAL_INVESTMENT',
                  })}
                  rangeInputName="initialAmount"
                  sliderValue={
                    initialAmountNumber ||
                    generateMinimumAmount(goalPayload.currency).amount
                  }
                  silderLabels={
                    <div className="d-flex justify-content-between goal-amount-slider-container">
                      <p>
                        {
                          generateMinimumAmount(goalPayload.currency)
                            .amountWithCommaAndCurrency
                        }
                      </p>
                      <p>
                        {
                          generateMaximumAmount(goalPayload.currency)
                            .amountWithCommaAndCurrency
                        }
                      </p>
                    </div>
                  }
                  min={generateMinimumAmount(goalPayload.currency).amount}
                  max={generateMaximumAmount(goalPayload.currency).amount}
                  step={
                    findCurrency(allCurrencies, goalPayload?.currency)
                      ?.sliderRange
                  }
                  input={
                    <>
                      <input
                        type="text"
                        className="text-center goal-calculator-input w-100 py-4"
                        name="initialAmount"
                        value={goalPayload?.initialAmount || ''}
                        onChange={handleChange}
                        placeholder={intl.formatMessage({
                          id: 'GOAL_CALCULATOR_PAGE.INPUT_PLACEHOLDER.AMOUNT',
                        })}
                        autoComplete="off"
                      />
                      <DisplayCorrectErrorMessage
                        formikError={formik.errors.initialAmount}
                        formikTouched={formik.touched.initialAmount}
                        amount={initialAmountNumber}
                        currency={findCurrency(
                          allCurrencies,
                          goalPayload?.currency
                        )}
                        input="initialAmount"
                      />
                    </>
                  }
                />
              </div>
              <div className="mt-5">
                <SliderInput
                  formik={formik}
                  handleChange={handleInvestingNowAmountChange}
                  title={intl.formatMessage({
                    id: 'GOAL_CALCULATOR_PAGE.LABEL.MONTHLY_INVESTMENT_A',
                  })}
                  titleb={intl.formatMessage({
                    id: 'GOAL_CALCULATOR_PAGE.LABEL.MONTHLY_INVESTMENT_B',
                  })}
                  rangeInputName="goalAmount"
                  sliderValue={goalAmountNumber}
                  silderLabels={
                    <div className="d-flex justify-content-between goal-amount-slider-container">
                      <p>
                        {`0 ${
                          goalPayload.currency ? goalPayload.currency : 'USD'
                        }`}
                      </p>
                      <p>
                        {
                          generateMaximumAmount(goalPayload.currency)
                            .amountWithCommaAndCurrency
                        }
                      </p>
                    </div>
                  }
                  min={0}
                  max={generateMaximumAmount(goalPayload.currency).amount}
                  step={
                    findCurrency(allCurrencies, goalPayload?.currency)
                      ?.sliderRange
                  }
                  input={
                    <>
                      <input
                        type="text"
                        className="text-center py-4 goal-calculator-input w-100"
                        name="goalAmount"
                        value={goalPayload?.goalAmount || ''}
                        onChange={handleChange}
                        placeholder={intl.formatMessage({
                          id: 'GOAL_CALCULATOR_PAGE.INPUT_PLACEHOLDER.AMOUNT',
                        })}
                        autoComplete="off"
                      />

                      <DisplayCorrectErrorMessage
                        formikError={formik.errors.goalAmount}
                        formikTouched={formik.touched.goalAmount}
                        amount={goalAmountNumber}
                        currency={findCurrency(
                          allCurrencies,
                          goalPayload?.currency
                        )}
                        input="goalAmount"
                      />
                    </>
                  }
                />
              </div>

              <div className="my-5">
                <SliderInput
                  formik={formik}
                  handleChange={handleChange}
                  title={intl.formatMessage({
                    id: 'GOAL_CALCULATOR_PAGE.LABEL.INVESTMENT_PERIOD',
                  })}
                  rangeInputName="targetDate"
                  sliderValue={years * 12 + months}
                  silderLabels={
                    <div className="d-flex justify-content-between goal-amount-slider-container mt-2">
                      <p>
                        {intl.formatMessage({
                          id: 'GOAL_CALCULATOR_PAGE.LABEL.FROM',
                        })}
                      </p>
                      <p>
                        {intl.formatMessage(
                          { id: 'GOAL_CALCULATOR_PAGE.LABEL.TO' },
                          { maxYears }
                        )}
                      </p>
                    </div>
                  }
                  min={1}
                  max={maxYears * 12}
                  step={1}
                  input={
                    <>
                      <div className="d-flex justify-content-between mx-auto goal-amount-slider-container">
                        <div>
                          <input
                            type="text"
                            className="text-center goal-calculator-input py-4"
                            style={{
                              width: '85px',
                            }}
                            name="targetDate"
                            id="years"
                            value={years.toString()}
                            onChange={handleYearChange}
                            autoComplete="off"
                          />
                          <span style={{ fontSize: '14px' }} className="ml-3">
                            {intl.formatMessage({
                              id: 'GOAL_CALCULATOR_PAGE.LABEL.YEAR',
                            })}
                          </span>
                        </div>

                        <div>
                          <input
                            type="text"
                            className="text-center py-4 goal-calculator-input"
                            style={{
                              width: '85px',
                            }}
                            name="targetDate"
                            value={months.toString()}
                            id="months"
                            onChange={handleYearChange}
                            autoComplete="off"
                          />
                          <span style={{ fontSize: '14px' }} className="ml-2">
                            {intl.formatMessage({
                              id: 'GOAL_CALCULATOR_PAGE.LABEL.MONTH',
                            })}
                          </span>
                        </div>
                      </div>
                      {targetDateError ? (
                        <InputValidationErrorMessage
                          message={targetDateError}
                        />
                      ) : null}
                    </>
                  }
                />
              </div>
            </div>
            <div className="container-width">
              <div className="mt-3 p-5 bg-gray-100 border-radius-16">
                {intl.formatMessage({ id: 'GOAL_CALCULATOR_PAGE.DISCLAIMER' })}
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Button
              buttonText={intl.formatMessage({ id: 'BUTTONS.INVEST_NOW' })}
              classes={'w-100 bg-red white-text font-size-20 py-5'}
              buttonType="submit"
            />
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, goal.actions)(GoalCalculator)
);
