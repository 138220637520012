import React from 'react';

const GoalIcon = ({ iconSrc }) => {
  return (
    <div className="goal-icon-container">
      <img className="goal-icon" src={iconSrc} alt="Goal Icon" />
    </div>
  );
};

export default GoalIcon;
