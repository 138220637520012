import { put, takeLatest } from 'redux-saga/effects';

export const actionTypes = {
  GoalName: '[GoalName] Action',
  InitialInvestmentAmount: '[InitialInvestmentAmount] Action',
  GoalAmount: '[GoalAmount] Action',
  BondPlans: '[BondPlans] Action',
  CashPlans: '[CashPlans] Action',
  EquityPlans: '[EquityPlans] Action',
  InvestmentId: '[InvestmentId] Action',
  Currency: '[Currency] Action',
  ReachMonth: '[ReachMonth] Action',
  ReachYear: '[ReachYear] Action',
  MonthlyAmount: '[MonthlyAmount] Action',
  GraphData: '[GraphData] Action',
  PlanId: '[PlanId] Action',
  UpdateGoalDetails: '[UpdateGoalDetails] Action',
};

const initialGoalState = {
  purpose: '',
  investmentId: 0,
  currency: '',
  targetMonth: '',
  targetYear: '',
  initialInvestmentAmount: 0,
  goalAmount: 0,
  monthlyAmount: '',
  selectedBondPlans: [],
  selectedCashPlans: [],
  selectedEquityPlans: [],
  graphData: [],
  planId: null,
  riskProfileLevels: null,
};

export const reducer = (state = initialGoalState, action) => {
  switch (action.type) {
    case actionTypes.UpdateGoalDetails: {
      // const { goalName } = action.payload;
      return { ...state, ...action.payload };
    }

    case actionTypes.GoalName: {
      const { goalName } = action.payload;
      return { ...state, goalName };
    }
    case actionTypes.InitialInvestmentAmount: {
      const { initialInvestmentAmount } = action.payload;
      return { ...state, initialInvestmentAmount };
    }
    case actionTypes.GoalAmount: {
      const { goalAmount } = action.payload;
      return { ...state, goalAmount };
    }
    case actionTypes.BondPlans: {
      const { selectedBondPlans } = action.payload;
      if (
        state.selectedBondPlans.length !== 0 &&
        state.selectedBondPlans.id === selectedBondPlans.id
      ) {
        let newPlan = [];
        return { ...state, selectedBondPlans: newPlan };
      }
      return { ...state, selectedBondPlans };
    }
    case actionTypes.CashPlans: {
      const { selectedCashPlans } = action.payload;
      if (
        state.selectedCashPlans.length !== 0 &&
        state.selectedCashPlans.id === selectedCashPlans.id
      ) {
        let newPlan = [];
        return { ...state, selectedCashPlans: newPlan };
      }
      return { ...state, selectedCashPlans };
    }
    case actionTypes.EquityPlans: {
      const { selectedEquityPlans } = action.payload;
      if (
        state.selectedEquityPlans.length !== 0 &&
        state.selectedEquityPlans.id === selectedEquityPlans.id
      ) {
        let newPlan = [];
        return { ...state, selectedEquityPlans: newPlan };
      }
      return { ...state, selectedEquityPlans };
    }
    case actionTypes.InvestmentId: {
      const { investmentId } = action.payload;
      return { ...state, investmentId };
    }
    case actionTypes.Currency: {
      const { currency } = action.payload;
      return { ...state, currency };
    }
    case actionTypes.ReachMonth: {
      const { targetMonth } = action.payload;
      return { ...state, targetMonth };
    }
    case actionTypes.ReachYear: {
      const { targetYear } = action.payload;
      return { ...state, targetYear };
    }
    case actionTypes.MonthlyAmount: {
      const { monthlyAmount } = action.payload;
      return { ...state, monthlyAmount };
    }
    case actionTypes.GraphData: {
      const { graphData } = action.payload;
      return { ...state, graphData };
    }
    case actionTypes.PlanId: {
      const { planId } = action.payload;
      return { ...state, planId };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  updateGoalDetails: (goalDetails) => ({
    type: actionTypes.UpdateGoalDetails,
    payload: { goalDetails },
  }),

  goalName: (goalName) => ({
    type: actionTypes.GoalName,
    payload: { goalName },
  }),
  investmentId: (investmentId) => ({
    type: actionTypes.InvestmentId,
    payload: { investmentId },
  }),
  currency: (currency) => ({
    type: actionTypes.Currency,
    payload: { currency },
  }),
  targetMonth: (targetMonth) => ({
    type: actionTypes.ReachMonth,
    payload: { targetMonth },
  }),
  targetYear: (targetYear) => ({
    type: actionTypes.ReachYear,
    payload: { targetYear },
  }),

  monthlyAmount: (monthlyAmount) => ({
    type: actionTypes.MonthlyAmount,
    payload: { monthlyAmount },
  }),
  initialInvestmentAmount: (initialInvestmentAmount) => ({
    type: actionTypes.InitialInvestmentAmount,
    payload: { initialInvestmentAmount },
  }),
  goalAmount: (goalAmount) => ({
    type: actionTypes.GoalAmount,
    payload: { goalAmount },
  }),
  selectBondPlan: (selectedBondPlans) => ({
    type: actionTypes.BondPlans,
    payload: { selectedBondPlans },
  }),
  selectCashPlan: (selectedCashPlans) => ({
    type: actionTypes.CashPlans,
    payload: { selectedCashPlans },
  }),
  selectEquityPlan: (selectedEquityPlans) => ({
    type: actionTypes.EquityPlans,
    payload: { selectedEquityPlans },
  }),
  graphData: (graphData) => ({
    type: actionTypes.GraphData,
    payload: { graphData },
  }),
  planId: (planId) => ({
    type: actionTypes.PlanId,
    payload: { planId },
  }),
};

export function* saga() {}
