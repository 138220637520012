import React from 'react';
import CommunicationAuthorityOfKenya from '../dashboard-images/CommunicationAuthorityOfKenya.svg';
import ICIFA from '../dashboard-images/ICIFA.svg';
import CapitalMarketAuthority from '../dashboard-images/CapitalMarketAuthority.svg';
import NairobiSecurityExchange from '../dashboard-images/NairobiSecurityExchange.svg';
import { injectIntl } from 'react-intl';

const DashboardCompanyLogos = ({
  containerClass,
  dashboardCompanyClass,
  intl,
}) => {
  return (
    <div style={{ flex: '1 1 0px' }} className={`${containerClass} p-5 mt-5`}>
      <p className="text-center font-weight-bold dashboard-logo-header">
        {intl.formatMessage({
          id: 'DASHBOARD.REGULATED_BY',
        })}
      </p>
      <div className="d-flex flex-wrap justify-content-center">
        <div className={`${dashboardCompanyClass}`}>
          <img width="100%" height="100%" src={ICIFA} alt="ICIFA" />
        </div>
        <div className={`${dashboardCompanyClass}`}>
          <img
            width="100%"
            height="100%"
            src={CapitalMarketAuthority}
            alt="Capital Market Authority"
          />
        </div>

        <div className={`${dashboardCompanyClass}`}>
          <img
            width="100%"
            height="100%"
            src={CommunicationAuthorityOfKenya}
            alt="Communication Authority of Kenya"
          />
        </div>

        <div className={`${dashboardCompanyClass}`}>
          <img
            width="100%"
            height="100%"
            src={NairobiSecurityExchange}
            alt="Nairobi Security Exchange"
          />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(DashboardCompanyLogos);
