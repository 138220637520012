import React from 'react';

const GoalTragetAmountProgressBar = ({ cuurentValue, max }) => {
  return (
    <>
      <div className="goal-progress">
        <div
          className="goal-progress-bar"
          role="progressbar"
          style={{
            width: `${
              cuurentValue && cuurentValue > 0
                ? (cuurentValue / max) * 100
                : '0'
            }%`,
          }}
          aria-valuenow={cuurentValue}
          aria-valuemin={0}
          aria-valuemax={100}
        ></div>
      </div>
    </>
  );
};

export default GoalTragetAmountProgressBar;
