const convertAmountToNumber = (amount) => {
  return Number(
    amount
      ?.toString()
      ?.split(',')
      .join('')
  );
};

export default convertAmountToNumber;
