import React from 'react';
import { injectIntl } from 'react-intl';

import airInvest from '../../../images/airinvest.svg';
import { Button } from '../../Common/AllButtons';
import { PageTitle } from '../../Common/PageTitle';

const SetupYourGoal = ({ intl }) => {
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.LAST_PAGE.TITLE' })}
        previousPage="/how-it-works"
        insideContainer
      />
      <div className="container text-center mt-30 min-vh-100">
        <img src={airInvest} alt="airInvest" />
        <div className="red-text-color font-weight-bold font-size-15 mt-10">
          {intl.formatMessage({ id: 'INSTRUCTIONS_PAGE.LAST_PAGE.HEADER' })}
        </div>
        <p className="how-it-works-text font-weight-bold font-size-15 mt-10">
          {intl.formatMessage({
            id: 'INSTRUCTIONS_PAGE.LAST_PAGE.DESCRIPTION',
          })}
        </p>

        <Button
          classes="bg-red white-text py-5 w-75 mt-10"
          buttonText={intl.formatMessage({ id: 'BUTTONS.START' })}
          link="/wizard/select-goal"
        />
        <div className="d-flex justify-content-center mt-5">
          <div className="page-indicator gray-dot"></div>
          <div className="page-indicator red-dot ml-2"></div>
        </div>
      </div>
    </>
  );
};

export default injectIntl(SetupYourGoal);
