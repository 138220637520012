import React from 'react';

export default function Input({
  placeholder,
  name,
  type,
  formik,
  className,
  defaultValue,
  handleChange,
}) {
  return (
    <input
      placeholder={placeholder}
      type={type}
      className={className}
      name={name}
      {...formik?.getFieldProps(`${name}`)}
      value={defaultValue || ''}
      onChange={handleChange}
      autoComplete="off"
    />
  );
}
