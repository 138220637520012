import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as goalActons from '../../../Goal/_redux/goalReducers';
import { Goal } from '../../../Common/Goal';
import { PageTitle } from '../../../Common/PageTitle';
import { getGoalNames } from '../../_redux/goalActions';

const GoalNames = ({ intl, updateGoalDetails }) => {
  const history = useHistory();
  const [goalNames, setGoalNames] = useState();

  useEffect(() => {
    const getAllGoalNames = async () => {
      try {
        const response = await getGoalNames();
        setGoalNames(response?.data?.items);
      } catch (error) {}
    };

    getAllGoalNames();
  }, []);

  const handleSelect = (e, goal) => {
    if (goal?.goalNameIsRequired) {
      updateGoalDetails({
        goalNameIsRequired: goal?.goalNameIsRequired,
        id: goal?.id,
      });
      history.push('/portfolio/goal/new');
    } else {
      localStorage.setItem('previousPage', '/portfolio/goal/select-goal');
      updateGoalDetails({
        name: goal?.name,
        id: goal?.id,
        goalNameIsRequired: goal?.goalNameIsRequired,
      });
      history.push('/portfolio/goal/calculator');
    }
  };

  const previousPageUrl = localStorage.getItem('previousPage');
  return (
    <>
      <PageTitle
        title={intl.formatMessage({ id: 'WIZARD.SELECT_GOAL.TITLE' })}
        previousPage={previousPageUrl ? previousPageUrl : '/dashboard'}
        insideContainer
      />
      <div className="mt-25 min-vh-100">
        {goalNames && goalNames.length
          ? goalNames.map((goal) => (
              <Goal
                goalName={goal?.name}
                goalIcon={goal?.iconLink}
                onClick={handleSelect}
                id={goal?.id}
                key={goal?.id}
                goalDescription={goal?.description}
                goal={goal}
              />
            ))
          : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  goal: state.goal,
});

export default injectIntl(
  connect(mapStateToProps, { ...goalActons.actions })(GoalNames)
);
