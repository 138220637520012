import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import '../styles/registration.scss';
import * as auth from '../_redux/authRedux';

const Login = (props) => {
  const { intl } = props;

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <div className="" id="kt_login_signin_form">
      {intl.formatMessage({ id: 'WELCOME_PAGE.WELCOME_TEXT' })}
    </div>
  );
};

export default injectIntl(
  connect(null, { ...auth.actions })(Login)
);
